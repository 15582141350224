<template>
    <div>
        <v-combobox :label="$t('ประเทศ')"
                    outlined
                    :rules="internationalRules"
                    :items=countryList
                    v-model="country"
                    hide-details="auto"
                    class="mb-4"></v-combobox>
        <v-text-field :label="$t('รัฐ / จังหวัด')"
                      outlined
                      :rules="internationalRules"
                      v-model="custom_province"
                      validate-on-blur
                      v-disabled-icon-focus
                      clearable
                      hide-details="auto"
                      class="mb-4"></v-text-field>
        <v-text-field :label="$t('เมือง / เขต')"
                      outlined
                      :rules="internationalRules"
                      v-model="custom_district"
                      validate-on-blur
                      v-disabled-icon-focus
                      clearable
                      hide-details="auto"
                      class="mb-4"></v-text-field>
        <v-text-field style="max-width: 160px"
                      :label="$t('รหัสไปรษณีย์')"
                      :rules="rulesCommon"
                      validate-on-blur
                      clearable
                      v-disabled-icon-focus
                      outlined
                      v-model="postalCode"></v-text-field>
    </div>
</template>
<script>
import country_db from "../../../resources/country-code.json";
import MixinsDisabledIconFocus from "@/mixins/MixinsDisabledIconFocus.vue";
import { mapGetters } from "vuex";

export default {
    name: "InternationalAddress",
    mixins: [MixinsDisabledIconFocus],
    props: {
        value: {
            type: Object,
        },
        countries: {
            type: Array,
        }
    },
    data() {
        return {
            country: this.value?.country ? this.value?.country : null,
            postalCode: this.value?.postalCode ? this.value?.postalCode : null,
            custom_province: this.value?.custom_province ? this.value?.custom_province : null,
            custom_district: this.value?.custom_district ? this.value?.custom_district : null,
        };
    },
    computed: {
        ...mapGetters({
          shop: 'order/shop',
        }),
        rulesCommon() {
            return [
                v => !!v || this.$t('จำเป็นต้องระบุ')
            ];
        },
        internationalRules() {
            return [
                v => !!v || this.$t('จำเป็นต้องระบุ'),
                v => (/^[A-Za-z0-9\s,._/-]+$/).test(v) || this.$t('กรุณากรอกเฉพาะตัวอักษร A-Z, a-z, ตัวเลข และอักขระที่อนุญาต (, . _ - /)'),
                () => !!this.country_code || this.$t('กรุณาเลือกประเทศจากตัวเลือก')
            ];
        },
        countryDatabase() {
          // ดราม่า ONE CHINA
          if (this.shop && ((this.shop.web_id+'') === '1016537')) {
            const db = country_db;
            db['CN'] = 'Mainland China'
            db['HK'] = 'Hong Kong SAR'
            db['MO'] = 'Macao SAR'
            db['TW'] = 'Taiwan Region'
            return db
          } else {
            return country_db;
          }
        },
        countryList() {
            if(this.countries && this.countries.length > 0){
                return Object.entries(this.countryDatabase)
                    .filter(([code]) => this.countries.includes(code))
                    .map(([, name]) => name);
            }
            return Object.values(this.countryDatabase).sort();
        },
        country_code() {
            let code = null;
            let that = this;
            Object.keys(this.countryDatabase)
                .forEach(function eachKey(key) {
                    if(that.country === that.countryDatabase[key]){
                        code = key;
                    }
                });
            return code;
        },
        getPayload() {
            const payload = {};
            payload.country_code = this.country_code;
            payload.country = this.country;
            payload.zipcode = this.postalCode;
            payload.district = this.custom_district;
            payload.province = this.custom_province;

            return payload;
        }
    },
    methods: {
        emitInput() {
            this.$emit('input', {
                country: this.country,
                country_code: this.country_code,
                postalCode: this.postalCode,
                custom_province: this.custom_province,
                custom_district: this.custom_district,
            });
        },
    },
    watch: {
        postalCode() {
            this.emitInput();
        },
        custom_province() {
            this.emitInput();
        },
        custom_district() {
            this.emitInput();
        },
        country() {
            this.emitInput();
        },
        country_code() {
            this.emitInput();
        },
    },
    mounted() {

    },
}
</script>
<style scoped></style>