<template>
    <div :id="'step'+stepNumber"
         class="lnwme-container"
         :class="((isPass)?'completed':'') + ((isOpen)?' opened':' closed') + ((expanded)?' expanded':'') + ((canEdit)?' can_edit':'')">
        <debug-indicator file-name="src/components/order/Step.vue"
                         :detail="`isPass = ${isPass}`"/>
        <span v-if="false">isOpen : {{ isOpen }} , isPass : {{isPass}},  canEdit : {{canEdit}},  , expanded : {{expanded}}</span>
        <!-- header -->
        <!-- กรอกข้อมูลผ่านแล้ว -->
        <template v-if="isPass">
            <div class="stepHeader" @click="toggleExpanded()">
                <div class="stepL">
                    <span v-if="canEdit"
                          class="circle">
                        <v-icon v-if="!isOpen" color="white" :small="isDesktop" :x-small="!isDesktop">mdi-check-bold</v-icon>
                        <span v-else>{{ stepNumber }}</span>
                    </span>
                    <span v-else class="circle primary flex_center">
                        <v-icon color="white" :size="(isDesktop)?18:13">
                            <template v-if="stepMapping.buyerInfo === stepNumber">
                                mdi-account
                            </template>
                            <template v-else-if="stepMapping.paymentMethod === stepNumber">
                                mdi-contactless-payment
                            </template>
                            <template v-else-if="stepMapping.shippingMethod === stepNumber">
                                mdi-truck
                            </template>
                        </v-icon>
                    </span>
                    <span>{{ stepTitle }}</span>
                </div>
                <!-- หุบ อยู่ -->
                <div class="stepR"
                     v-if="!isOpen && canEdit">
                    <v-scroll-y-transition hide-on-leave>
                        <template v-if="canEdit && ( !(this.status == 'wait_order' || this.status == 'wait_payment') || expanded)">
                            <template v-if="stepMapping.shippingMethod === stepNumber">
                                <div class="flex flex_end">
                                    <v-btn @click="clickedEditBtn('step1')"
                                           right
                                           text
                                           height="36"
                                           :small="(!isDesktop)"
                                           color="primary">
                                        <template v-if="isDesktop && $i18n.locale == 'th'">
                                            {{ $t('แก้ไขที่อยู่') }}
                                        </template>
                                        <template v-else>
                                            <v-icon :x-small="!isDesktop" class="mr-1">mdi-pencil-circle</v-icon>{{ $t('ที่อยู่') }}
                                        </template>
                                    </v-btn>
                                    <v-btn @click="clickedEditBtn('step2')"
                                           right
                                           text
                                           height="36"
                                           :small="(!isDesktop)"
                                           color="primary">
                                        <template v-if="isDesktop && $i18n.locale == 'th'">
                                            {{ $t('แก้ไขวิธีจัดส่ง') }}
                                        </template>
                                        <template v-else>
                                            <v-icon :x-small="!isDesktop" class="mr-1">mdi-pencil-circle</v-icon>{{ $t('วิธีจัดส่ง') }}
                                        </template>
                                    </v-btn>
                                </div>
                            </template>
                            <template v-else>
                                <v-btn @click="clickedEditBtn()"
                                       right
                                       text
                                       color="primary">
                                    {{ $t('แก้ไข') }}
                                </v-btn>
                            </template>
                        </template>
                        <template v-else-if="canEdit && !expanded">
                            <div class="needDiv">
                                <span class="done_text color-light text-body-2" :class="(isDesktop)?'pr-4':''">
                                    <template v-if="stepMapping.buyerInfo === stepNumber">
                                        <template v-if="orderData.is_required_contact_mobile || orderData.contact_mobile">
                                            {{ mobile_format(orderData.contact_mobile) }}
                                        </template>
                                        <template v-else-if="orderData.contact_email ">
                                            {{ orderData.contact_email }}
                                        </template>
                                        <template v-else>{{ $t('เรียบร้อย') }}</template>
                                    </template>
                                    <template v-else-if="stepMapping.shippingMethod === stepNumber">
                                        <!-- isPOS-->
                                        <template v-if="orderData.owner_create_flag == 3">
                                            {{ $t('รับสินค้าหน้าร้าน') }}
                                        </template>
                                        <template v-else-if="orderData.shipping_type_id && orderData.shipping_types && orderData.shipping_types[orderData.shipping_type_id]">
                                            <template v-if="orderData.receiver && orderData.receiver.zipcode">{{ orderData.receiver.zipcode }}, </template>
                                            <template>{{ orderData.shipping_types[orderData.shipping_type_id].name }}</template>
                                        </template>
                                    </template>
                                </span>
                            </div>
                        </template>
                    </v-scroll-y-transition>
                    <template v-if="this.status == 'wait_order' || this.status == 'wait_payment'">
                        <v-icon color="primary" :class="(expanded)?'flip':''">mdi-chevron-down</v-icon>
                    </template>
                </div>
                <!--                <div class="stepR" v-else>-->
                <!--                    <v-btn  @click="toggleOpen()" right color="primary">ตกลง</v-btn>-->
                <!--                </div>-->
            </div>
        </template>
        <template v-else><!-- ยังไม่ผ่าน -->
            <div class="stepHeader"
                 @click="toggleStepSection">
                <div class="stepL">
                    <span class="circle"
                          v-if="whatShouldThisComputedCalled_1">{{ stepNumber }}</span> <span>{{ stepTitle }}</span>
                </div>
                <div class="stepR">
                    <!--                    <template v-if="stepMapping.buyerInfo === stepNumber && isOpen">-->
                    <!--                        <div class="minor">สำหรับแจ้งเตือน / ติดต่อกรณีมีปัญหา</div>-->
                    <!--                    </template>-->
                    <!--                    <template v-else>-->
                    <!--                        <div class="icon-chevron-down"></div>-->
                    <!--                    </template>-->
                    <v-icon color="primary" :class="(isOpen)?'flip':''">mdi-chevron-down</v-icon>
                </div>
            </div>
        </template>
        <!-- step body -->
        <v-scroll-y-transition hide-on-leave>
        <slot v-if="!(this.status == 'wait_order' || this.status == 'wait_payment') || isOpen || expanded"></slot>
        </v-scroll-y-transition>
    </div>
</template>
<script>
import {mapMutations, mapGetters, mapState} from 'vuex';
import DebugIndicator from "@/components/DebugTools/DebugIndicator";
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";

export default {
    name: "Step",
    data() {
        return {
            expanded: false
        }
    },
    components: {DebugIndicator},
    mixins: [MixinsHelper],
    props: {
        stepNumber: {
            type: Number,
            required: true
        },
        stepTitle: {
            type: String,
            required: true
        },
    },
    computed: {
        ...mapState({
            status: state => state.order.orderData.order_status,
            orderData: state => state.order.orderData,
        }),
        ...mapGetters({
            isPassStep1: 'order/isPassStep1',
            isPassStep2: 'order/isPassStep2',
            isPassStep3: 'order/isPassStep3',
            canEditStep1: 'order/canEditStep1',
            canEditStep2: 'order/canEditStep2',
            canEditStep3: 'order/canEditStep3',
            stepMapping: 'order/stepMapping',
            isOrderWaitPayment: 'order/isOrderWaitPayment',
        }),
        meta() {
            return this.$store.state.order.stepMetas[this.stepNumber];
        },
        isOpen() {
            return this.meta.open;
        },
        whatShouldThisComputedCalled_1() {
            return this.status === 'wait_order' || this.status === 'wait_payment' || this.status === 'wait_payment_verify';
        },
        isPass() {
            if (this.whatShouldThisComputedCalled_1) {
                return this[`isPassStep${this.stepNumber}`];
            }
            return true;
        },
        canEdit() {
            return this[`canEditStep${this.stepNumber}`];
        },
    },
    methods: {
        ...mapMutations({
            setStepMetas: 'order/setStepMetas',
            setStepMetaKeyValue: 'order/setStepMetaKeyValue',
        }),
        expandSection() {
            let i = 1;
            for (i; i <= 3; i++) {
                this.setStepMetaKeyValue({
                    stepNumber: i,
                    key: 'open',
                    value: (i === this.stepNumber),
                });
            }
            this.scrollTo('#step' + this.stepNumber);
        },
        shrinkSection() {
            this.setStepMetaKeyValue({
                stepNumber: this.stepNumber,
                key: 'open',
                value: false,
            });
        },
        toggleOpen() {
            let meta = Object.assign({}, this.meta);
            meta.open = !meta.open;
            this.setStepMetas({
                stepNumber: this.stepNumber,
                stepMeta: meta,
            });
        },
        toggleExpanded() {
            // if(this.status == 'wait_order'){
                this.expanded = !this.expanded;
            // }
        },
        toggleStepSection() {
            // ถ้าหุบอยู่ แต่เปิดมาก็แก้ไม่ได้ ให้ไม่ต้องขยายออกมา
            if (!this.isOpen && !this.canEdit)
                return;

            if (this.stepMapping.buyerInfo !== 1 || this.stepNumber > 1) {
                if (this.isOpen) {
                    if (
                        !(this.isOrderWaitPayment && this.stepMapping.paymentMethod === this.stepNumber) // ถ้าเป็น step การจ่ายเงิน จะไม่ให้หุบ
                    )
                        this.shrinkSection();
                } else {
                    if(this.checkNoPermissionAndPopup()) return;
                    this.expandSection();
                }
            } else {
                this.expandSection();
            }
        },
        clickedEditBtn(option) {
            if(this.checkNoPermissionAndPopup()) return;
            this.$emit('editBtnClicked');
            if(option == 'step1'){
                this.$emit('goToShippingType', 1);
            }else if(option == 'step2'){
                this.$emit('goToShippingType', 2);
            }
            this.expandSection();
        },
    },
    mounted(){
        if(!(this.status == 'wait_order' || this.status == 'wait_payment')) {
            this.expanded = true;
        }
    }
}
</script>
<style scoped></style>