<template>
    <div v-show="isLineNotifyEnabled">
        <div class="just_flex align-center py-2" style="gap: 10px;">
            <div class="needDiv">
                <v-checkbox v-model="lineNotifyCheckbox"
                            @change="connectLineNotify"
                            class="d-inline-block my-2 pt-0"
                            hide-details>
                    <template v-slot:label>
                    <span class="mr-2" style="color: rgba(0, 0, 0, .75)">{{ $t('แจ้งเตือนสถานะทาง') }} LINE <img style="width: 16px; margin-left: 2px" :src="require('@/assets/signin_logo/line.png')"
                                                                            alt=""></span>
                    </template>
                </v-checkbox>
                <component :is="isDesktop?'VDialog':'VBottomSheet'"
                           v-if="hasLineTokens"
                           v-model="cancelDialog"
                           persistent
                           scrollable
                           :max-width="isDesktop?'400px':'100%'"
                >
                    <v-card>
                        <v-card-title class="text-h5">
                            {{ $t('ยกเลิก') }} ?
                        </v-card-title>
                        <v-card-text>
                            {{ $t('คุณต้องการยกเลิกการส่งข้อความอัพเดทสถานะออเดอร์ไปยัง Line ใช่หรือไม่') }}
                            <div style="height: 10px;"></div>
                            {{ $t('ระบบจะพาคุณไปยังหน้าตั้งค่าการเชื่อมบัญชี Line และคุณสามารถลบบัญชีที่เชื่อมต่อได้ที่หน้านั้น') }}
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="primary"
                                text
                                @click="cancelDialog = false; checkLineTokens()"
                            >
                                {{ $t('ปิดหน้าต่าง') }}
                            </v-btn>
                            <v-btn
                                color="primary"
                                href="https://lnw.me/account/manage/setting#line_notify"
                                target="_blank"
                                @click="cancelDialog = false; waitUserActive()"
                            >
                                {{ $t('ใช่') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </component>
            </div>
            <v-tooltip class="d-inline-block"
                       max-width="300"
                       top>
                <template v-slot:activator="{ on }">
                    <div v-on="on"
                         class=" text-no-wrap">
                        <span v-if="false && isDesktop"
                              class="text--secondary text-caption mr-2">{{ $t('นี่คืออะไร') }}</span>
                        <v-icon dense>
                            mdi-help-circle-outline
                        </v-icon>
                    </div>
                </template>
                {{ $t('อัพเดทสถานะรายการสั่งซื้อนี้ (เช่น ร้านค้าจัดส่งสินค้าแล้ว) โดยระบบจะส่งข้อความสถานะให้คุณอัตโนมัติผ่านทาง') }} Line
            </v-tooltip>
        </div>
        <v-divider></v-divider>
    </div>
</template>
<script>
import {VDialog, VBottomSheet} from 'vuetify/lib';
import DebugIndicator from "@/components/DebugTools/DebugIndicator";
import {mapActions, mapGetters, mapState} from "vuex";
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";

export default {
    name: "LineNotify",
    props: {},
    data(){
        return {
            lineNotifyCheckbox: false,
            cancelDialog: false,
            userActive : false,
        }
    },
    mixins: [
        MixinsHelper,
    ],
    components: {
        DebugIndicator,
        VDialog,
        VBottomSheet
    },
    computed: {
        ...mapGetters({
            cpFlagIsLogin: 'user/isLogin',
            isLineNotifyEnabled: 'order/isLineNotifyEnabled',
        }),
        ...mapState({
            orderData: state => state.order.orderData,
            shopData: state => state.order.shopData,
            userData: state => state.user.userData,
            isFetchedLineTokens: state => state.user.called.fetchLineTokens,
            line_notify_tokens: state => state.user.line_notify_tokens,
        }),
        hasLineTokens() {
            return this.line_notify_tokens && this.line_notify_tokens.length > 0
        }
    },
    methods: {
        ...mapActions({
            actionFetchLineNotify: "user/fetchLineTokens",
        }),
        checkLineTokens() {
            if(this.hasLineTokens) {
                this.lineNotifyCheckbox = true;
            }else{
                this.lineNotifyCheckbox = false;
            }
            this.$emit('updateLineNotifyFlag', this.lineNotifyCheckbox);
        },
        waitUserActive() {
            document.addEventListener('mousemove', this.setUserActive);
            document.addEventListener('touchstart', this.setUserActive);
        },
        setUserActive() {
            this.userActive = true;

            // Remove the event listeners to stop monitoring once the user is active
            document.removeEventListener('mousemove', this.setUserActive);
            document.removeEventListener('touchstart', this.setUserActive);

            this.actionFetchLineNotify();
            this.checkLineTokens();
        },
        connectLineNotify() {
            if(!this.hasLineTokens){
                window.open('https://lnw.me/account/manage/setting/line_notify/connect');
                this.waitUserActive();
            }else if(this.hasLineTokens){
                this.cancelDialog = true;
            }
        }
    },
    beforeMount(){

    },
    mounted(){
        if (this.userData && !this.isFetchedLineTokens){
            this.actionFetchLineNotify().then(() => {
                this.checkLineTokens();
            });
        }
        this.checkLineTokens()
    },
    watch : {
        cpFlagIsLogin(newValue) {
            if(newValue){
                this.actionFetchLineNotify().then(() => {
                    this.checkLineTokens();
                });
            }
        }
    }
}
</script>