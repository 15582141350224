<template>
    <div v-if="isDropshipActive">
        <template v-if="isExpanded">
            <template v-if="false">
                <pre>
                form_sender_address_id : {{ form_sender_address_id }}
                orderData.dealer_id : {{ orderData.dealer_id }}
                orderData.dealer : {{ orderData.dealer }}
                </pre>
            </template>
            <v-card v-if="canEditSenderAddress"
                    :outlined="canEditSenderAddress"
                    :flat="!canEditSenderAddress"
                    :class="(canEditSenderAddress)?'mb-7':''">
                <v-scroll-y-transition hide-on-leave>
                    <div v-if="displayCurrentSenderAddress" class="">
                        <v-list-item>
                            <v-list-item-content class="pb-4">
                                <v-list-item-title style="white-space: normal">
                                    <div class="deep-orange--text text-caption mb-2">{{ $t('ที่อยู่ผู้ส่งพัสดุ  (ตัวเลือกสำหรับตัวแทน)') }}</div>
                                    <div class="d-inline-block mr-2"
                                         style="font-weight: bold">{{ orderData.dealer.name }}
                                    </div>
                                    <div class="d-inline-block"
                                         style="font-weight: bold">{{ orderData.dealer.telephone }}
                                    </div>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    <template v-if="orderData.dealer.use_shop_address">
                                        <span class="disabled">[ Auto - {{ $t('ที่อยู่ของร้าน') }} ]</span>
                                    </template>
                                    <template v-else>
                                        {{ orderData.dealer.address }} {{ orderData.dealer.subdistrict }} {{ orderData.dealer.district }} {{ orderData.dealer.province }} {{ orderData.dealer.zipcode }}
                                    </template>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action v-if="canEditSenderAddress">
                                <template v-if="form_sender_address_id">
                                    <a class="primary--text font-weight-normal"
                                       @click="openSenderDialog"
                                       style="line-height: 1.5;">{{ $t('เปลี่ยน') }}</a>
                                </template>
                                <template v-else>
                                    <a class="font-weight-normal"
                                       @click="editSenderAddress(false)"
                                       style="line-height: 1.5;">{{ $t('แก้ไข') }}</a>
                                </template>
                            </v-list-item-action>
                        </v-list-item>
                    </div>
                    <template v-else-if="!addressDialog">
                        <v-alert dense
                                 text
                                 type="warning"
                                 class="mx-6">
                            <div class="just_flex">
                                <span>{{ $t('กรุณาเลือกที่อยู่ผู้ส่ง') }}</span>
                                <v-btn color="warning"
                                       large
                                       @click="openSenderDialog">{{ $t('เลือก') }}
                                </v-btn>
                            </div>
                        </v-alert>
                    </template>
                </v-scroll-y-transition>
            </v-card>
            <component :is="isDesktop?'VDialog':'VBottomSheet'"
                       v-model="addressDialog"
                       persistent
                       :max-width="isDesktop?'600px':''"
                       :transition="isDesktop?'dialog-transition':'dialog-bottom-transition'"
                       scrollable
                       class="px-4">
                <v-overlay :value="overlay"
                           absolute="absolute"
                           opacity="0.1"
                           z-index="999">
                    <v-progress-circular indeterminate
                                         size="64"></v-progress-circular>
                </v-overlay>
                <template v-slot:activator="{on,attrs}">
                    <slot name="activator"
                          :on="on"
                          :attr="attrs"></slot>
                </template>
                <!-- dialog ที่อยู่ผู้ส่ง -->
                <v-card>
                    <v-card-title class="just_flex">
                        <div>{{ $t('ที่อยู่ผู้ส่งพัสดุ') }}</div>
                        <v-btn icon
                               @click="addressDialog = false">
                            <v-icon color="primary">mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text ref="senderScroll"
                                 style="height: 600px; max-height: calc(var(--vh, 1vh) * 80); background-color: #fafafa; padding-top: 20px">

                        <template v-if="allMoreThanPageLimit && !isAddressEditorFormShow">
                            <v-text-field class="mt-3"
                                          clearable
                                          v-disabled-icon-focus
                                          outlined
                                          v-model.trim="search_textbox"
                                          @keypress.enter="searchAddressesEnter($event)">
                                <template v-slot:label>
                                    {{ $t('ค้นหาที่อยู่ ด้วยชื่อ เบอร์โทร หรืออีเมล') }}
                                </template>
                                <v-btn color="primary"
                                       style="margin-top: -18px !important;"
                                       x-large
                                       @click="searchAddressesEnter($event)"
                                       slot="append-outer">
                                    {{ $t('ค้นหา') }}
                                </v-btn>
                            </v-text-field>
                        </template>

                        <!-- แสดง list sender contact มาให้เลือก -->
                        <v-scroll-y-transition>
                            <div v-if="!isAddressEditorFormShow">
                                <v-card class="mx-auto"
                                        outlined>
                                    <v-list-item-group top
                                                       style="background-color: white;">
                                        <v-radio-group hide-details="auto"
                                                       v-model="form_sender_address_id">
                                            <div class="w-100"
                                                 v-for="index in sortedSenderAddresses"
                                                 :key="index">
                                                <label v-if="senderAddresses[index]">
                                                    <v-list-item>
                                                        <v-list-item-action>
                                                            <v-radio color="primary"
                                                                     :value="index"></v-radio>
                                                        </v-list-item-action>
                                                        <v-list-item-content>
                                                            <v-list-item-title>
                                                                <div style="white-space: normal">
                                                                    <div class="d-inline-block mr-2"
                                                                         style="font-weight: bold">{{ senderAddresses[index].name }}
                                                                    </div>
                                                                    <div class="d-inline-block"
                                                                         style="font-weight: bold">{{ senderAddresses[index].telephone }}
                                                                    </div>
                                                                </div>
                                                            </v-list-item-title>
                                                            <v-list-item-subtitle>
                                                                <template v-if="senderAddresses[index].use_shop_address">
                                                                    <span class="disabled">[ Auto - {{ $t('ที่อยู่ของร้าน') }} ]</span>
                                                                </template>
                                                                <template v-else>
                                                                    {{ senderAddresses[index].address }} {{ senderAddresses[index].subdistrict }} {{ senderAddresses[index].district }} {{ senderAddresses[index].province }} {{ senderAddresses[index].zipcode }}
                                                                </template>
                                                            </v-list-item-subtitle>
                                                        </v-list-item-content>
                                                        <v-list-item-action>
                                                            <a class="font-weight-normal"
                                                               @click="editSenderAddress(index)"
                                                               style="line-height: 1.5;">{{ $t('แก้ไข') }}</a>
                                                        </v-list-item-action>
                                                    </v-list-item>
                                                </label>
                                                <v-divider></v-divider>
                                            </div>
                                            <div class="w-100">
                                                <label class="d-block">
                                                    <v-list-item @click="addNewAddress()">
                                                        <v-list-item-action>
                                                            <v-radio color="primary"
                                                                     :value="-1"></v-radio>
                                                        </v-list-item-action>
                                                        <v-list-item-content>
                                                            <v-list-item-title style="margin-bottom: 0">
                                                                {{ $t('เพิ่มที่อยู่ผู้ส่งใหม่') }}
                                                            </v-list-item-title>
                                                        </v-list-item-content>
                                                        <v-list-item-action>
                                                            <v-icon>mdi-chevron-right</v-icon>
                                                        </v-list-item-action>
                                                    </v-list-item>
                                                </label>
                                            </div>
                                        </v-radio-group>
                                        <v-divider></v-divider>
                                        <div v-if="hasMore">
                                            <label>
                                                <v-list-item @click="loadMoreAddresses()">
                                                    <v-list-item-action>
                                                        <v-icon>mdi-chevron-down</v-icon>
                                                    </v-list-item-action>
                                                    <v-list-item-content>
                                                        <v-list-item-title style="margin-bottom: 0">
                                                            <span style="font-weight: normal">{{ $t('โหลดรายการที่อยู่เพิ่มเติม') }}</span>
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </label>
                                        </div>
                                    </v-list-item-group>
                                </v-card>
                            </div>
                        </v-scroll-y-transition>
                        <!-- แสดง form มาให้กรอก เพื่อสร้างใหม่ หรือ edit -->
                        <v-form ref="form"
                                v-model="formValid">
                            <v-scroll-y-transition hide-on-leave>
                                <div v-if="isAddressEditorFormShow">
                                    <v-alert text
                                             type="info"
                                             class="mb-6"
                                             color="#666">
                                        <div class="just_flex">
                                            <span>{{ editSenderAddressId > 0 ? $t('คุณกำลังแก้ไขที่อยู่เดิม') : $t('คุณกำลังเพิ่มที่อยู่ใหม่') }}</span>
                                            <template v-if="sortedSenderAddresses.length > 0">
                                                <a class="primary--text" @click="closeAddressEditorForm()">เปลี่ยน</a>
                                            </template>
                                        </div>
                                    </v-alert>
                                </div>
                            </v-scroll-y-transition>
                            <template v-if="isAddressEditorFormShow">
                                <v-radio-group
                                    v-model="sender.use_shop_address"
                                    row>
                                    <v-radio
                                        :label="$t('ที่อยู่ของร้าน')"
                                        :value="1"
                                    ></v-radio>
                                    <v-radio
                                        :label="$t('ที่อยู่ของฉัน')"
                                        :value="0"
                                    ></v-radio>
                                </v-radio-group>
                                <template v-if="sender.use_shop_address">
                                    <v-textarea :label="$t('ที่อยู่จัดส่งพัสดุ')"
                                                persistent-hint
                                                disabled
                                                auto-grow
                                                outlined
                                                rows="3"
                                                class="mb-3"
                                                :rules="commonRules"
                                                :value="'[ Auto - '+$t('ที่อยู่ของร้าน')+']'"></v-textarea>
                                </template>
                                <template v-else>
                                    <v-textarea :label="$t('เลขที่ อาคาร ซอย ถนน และรายละเอียดอื่นๆ')"
                                                persistent-hint
                                                auto-grow
                                                outlined
                                                rows="3"
                                                class="mb-3"
                                                ref="receiverAddress"
                                                :rules="commonRules"
                                                v-model.trim="sender.address"></v-textarea>
                                    <thailand-address ref="thailandAddress"
                                                      v-model="thailandAddressData"></thailand-address>
                                </template>
                                <v-divider dark></v-divider>
                                <v-text-field ref="receiverName"
                                              class="mt-2"
                                              :label="$t('ชื่อสกุลผู้ส่ง')"
                                              :rules="commonRules"
                                              validate-on-blur
                                              clearable
                                              v-disabled-icon-focus
                                              outlined
                                              v-model="sender.name"></v-text-field>
                                <v-text-field ref="receiverMobileNumber"
                                              :label="$t('เบอร์โทรผู้ส่ง')"
                                              :rules="anyPhoneNumberRules"
                                              validate-on-blur
                                              pattern="\d*"
                                              maxlength=30
                                              clearable
                                              v-disabled-icon-focus
                                              outlined
                                              v-model="sender.telephone"></v-text-field>
                            </template>
                        </v-form>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions style="justify-content: space-between">
                        <v-btn color="darken-1"
                               text
                               @click="addressDialog = false">
                            <div class="icon-chevron-left"></div>
                            {{ $t('กลับ') }}
                        </v-btn>
                        <v-btn color="primary"
                               large
                               style="min-width: 200px"
                               :disabled="disabledSubmitButton"
                               :class="(form_sender_address_id !== null)?'ready':''"
                               @click="submitSenderAddress">
                            {{ $t('ยืนยัน') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </component>
        </template>
        <template v-else>
            <div v-if="displayCurrentSenderAddress">
                <v-divider></v-divider>
                <v-list-item>
                    <v-list-item-content>
                        <div class="minor my-4">
                            {{ $t('ที่อยู่ผู้ส่งพัสดุ') }}
                        </div>
                        <v-list-item-title style="white-space: normal">
                            <div class="d-inline-block mr-2"
                                 style="font-weight: bold">{{ sender.name }}
                            </div>
                            <div class="d-inline-block"
                                 style="font-weight: bold">{{ sender.telephone }}
                            </div>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            <template v-if="sender.use_shop_address">
                                <span class="disabled">[ Auto - {{ $t('ที่อยู่ของร้าน') }} ]</span>
                            </template>
                            <template v-else>
                                {{ sender.address }} {{ sender.subdistrict }} {{ sender.district }} {{ sender.province }} {{ sender.zipcode }}
                            </template>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </div>
        </template>
    </div>
</template>
<script>
import {VDialog, VBottomSheet} from 'vuetify/lib';
import {mapActions, mapGetters, mapState} from "vuex";
import DebugIndicator from "@/components/DebugTools/DebugIndicator";
import ThailandAddress from "@/components/Address/ThailandAddress.vue";
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";
import store from "@/store";

export default {
    name: "SenderAddress",
    mixins: [
        MixinsHelper,
    ],
    props: {
        isExpanded: {
            required: true,
            type: Boolean,
        },
    },
    components: {
        DebugIndicator,
        VDialog,
        VBottomSheet,
        ThailandAddress,
    },
    data() {
        return {
            overlay: false,
            formValid: null,
            addressDialog: false, //หน้าต่างเลือกที่อยู่ผู้ส่ง
            commonRules: [
                v => !!v || this.$t('จำเป็นต้องระบุ'),
            ],
            thailandAddressData: {},
            // สำหรับเพิ่ม / แก้ไข ที่อยู่ผู้ส่ง
            sender: {
                name: '',
                telephone: '',
                address: '',
                use_shop_address: 0
            },
            is_dummy_sender_contact : false,
            search_textbox: '',
        };
    },
    computed: {
        ...mapState({
            orderData: state => state.order.orderData,
            allMoreThanPageLimit: state => state.user.paginated_metadata.senderAddresses.allMoreThanPageLimit,
            hasMore: state => state.user.paginated_metadata.senderAddresses.more,
        }),
        ...mapGetters({
            isDropshipActive: 'order/isDropshipActive',
            sortedSenderAddresses: 'user/use_this_sortedSenderAddresses',
            senderAddresses: 'user/use_this_senderAddresses',
            canEditSenderAddress: 'order/canEditReceiverContact', // ยังสามารถแก้ไขข้อมูล sender ได้ไหม โดยใช้ตัวแปรเดียวกันกับ receiver
            hasSpecifiedSenderData: 'order/hasSpecifiedSenderData',
        }),
        form_sender_address_id: {
            set(value) {
                this.$store.commit('order/setForm', {key: 'selectedSenderContactId', value: value});
            },
            get() {
                return this.$store.state.order.form.selectedSenderContactId;
            },
        },
        isAddressEditorFormShow() {
            return this.editSenderAddressId !== 0;
        },
        // เปิดฟอร์มเพื่อแก้ไข sender contact ที่มีอยู่แล้ว 0=ไม่ได้เปิดใช้ฟอร์ม  -1=กำลังสร้างรายการใหม่  id=กำลังแก้ไขรายการไหน
        editSenderAddressId: {
            set(value) {
                this.$store.commit('order/setForm', {editSenderAddressId: value});
            },
            get() {
                return this.$store.state.order.form.editSenderAddressId;
            },
        },
        disabledSubmitButton() {

            // กำลังจะใช้ข้อมูลจากฟอร์ม ก็เลยต้อง validate ข้อมูลในฟอร์ม
            if (this.isAddressEditorFormShow) {
                return !this.$refs.form.validate();
            }

            return !this.form_sender_address_id;
        },
        displayCurrentSenderAddress() {
            return this.hasSpecifiedSenderData;
        },
        anyPhoneNumberRules(){
            return [
                v => !!v || this.$t('จำเป็นต้องระบุ'),
                v => !!/^((0[689][0-9]{8})|(0[23457][0-9]{7}))$/.test(v) || this.$t('รูปแบบไม่ถูกต้อง'),
            ];
        },
    },
    methods: {
        ...mapActions({
            updateOrderSender: 'order/updateOrderSender',
            _loadMoreAddresses: 'user/loadMoreAddresses',
            _searchAddresses: 'user/searchAddresses',
        }),
        searchAddressesEnter(event){
            if(this.search_textbox && this.search_textbox != ""){
                this.searchAddresses();
            }else{
                this.abortSearchAddresses();
            }
            if(event){
                event.target.blur();
                event.preventDefault();
            }
            return false;
        },
        submitSenderAddress() {
            const payload = {};
            store.dispatch('order/setIsShowOverlay', true);
            // เลือกเฉยๆ ไม่ได้เปิด editor เพื่อแก้ไข
            if (this.editSenderAddressId === 0)
                payload.sender_id = this.form_sender_address_id;
            else {
                // กำลังแก้ไข sender address อันไหนอยู่
                payload.sender = Object.assign({}, this.$refs.thailandAddress.getPayload);

                payload.sender.address = this.sender.address;
                payload.sender.name = this.sender.name;
                payload.sender.telephone = this.sender.telephone;
                payload.sender.use_shop_address = this.sender.use_shop_address;

                if(this.$store.state.order.form.buyerInfoEmail)
                    payload.sender.email = this.$store.state.order.form.buyerInfoEmail;
                if(this.$store.state.order.form.buyerInfoMobileNumber)
                    payload.sender.mobile = this.$store.state.order.form.buyerInfoMobileNumber;

                if(!this.is_dummy_sender_contact){
                    if(this.editSenderAddressId > 0){
                        payload.sender_id = this.editSenderAddressId;
                        payload.sender.contact_id = this.editSenderAddressId;
                    }
                }
            }

            this.updateOrderSender(payload)
                .then(() => {
                    this.editSenderAddressId = 0; // เพื่อไม่เลือกว่ากำลังแก้ receiver contact ใดๆ
                    this.addressDialog = false;
                    store.dispatch('order/setIsShowOverlay', false);
                });
        },
        addNewAddress() { // กดปุ่มเพิ่มที่อยู่ใหม่
            this.editSenderAddress(-1);
        },
        // เปิดฟอร์มเพื่อแก้ไข sender contact ที่มีอยู่แล้ว
        editSenderAddress(id) {
            this.editSenderAddressId = id;

            if (id === -1) { // เพิ่มที่อยู่ใหม่ จะส่ง id มาเป็น -1
                this.form_sender_address_id = -1;

                this.clearSenderForm();
            }
            else if (id === false) {
                this.extractSenderData(this.orderData.dealer);
                this.addressDialog = true;
            }
            else { // แก้ไขที่อยู่เดิม
                this.form_sender_address_id = id; // เวลากด "แก้ไข" ที่อยู่ เราจะให้เลือกตัวเลือกนี้ไว้ด้วยเลย
                this.is_dummy_sender_contact = false;
                this.extractSenderData(this.senderAddresses[id]);
                if(this.senderAddresses[id].dummy)
                    this.is_dummy_sender_contact = true;
            }


            let that = this;
            setTimeout(function () {
                that.$refs.senderScroll.scrollTop = 0;
            }, 300);
        },
        clearSenderForm() {
            this.sender.name = '';
            this.sender.address = '';
            this.sender.telephone = '';
            this.sender.use_shop_address = 0;
            this.thailandAddressData = {};
        },
        closeAddressEditorForm() {
            this.editSenderAddressId = 0;
        },
        // กดเปิดเพื่อเปลี่ยน ข้อมูล senderAddress : แสดงเป็น dialog ขึ้นมา
        openSenderDialog() {
            if(this.checkNoPermissionAndPopup()) return;
            if (this.orderData.dealer_id) {
                // เพื่อให้ย้อนกลับมาใน step ที่แสดง list ของ contacts เพื่อให้เลือก
                this.editSenderAddressId = 0;
                this.form_sender_address_id = this.orderData.dealer_id;
            } else {
                if (this.sortedSenderAddresses && this.sortedSenderAddresses.length === 0) {
                    this.addNewAddress();
                }
            }
            this.addressDialog = true;
        },
        extractSenderData(sender) {
            this.sender.name = sender.name;
            this.sender.address = sender.address;
            this.sender.telephone = sender.telephone;
            this.sender.use_shop_address = sender.use_shop_address;
            var id = this.form_sender_address_id;
            this.thailandAddressData.postalCode = this.senderAddresses[id] ? this.senderAddresses[id].zipcode : null;

            if(this.senderAddresses[id].subdistrict_id){
                this.thailandAddressData.selectedPostalList = [
                    this.senderAddresses[id].subdistrict_id,
                    this.senderAddresses[id].district_id,
                    this.senderAddresses[id].province_id
                ].join(' ');
            }

            if(this.senderAddresses[id].province)
                this.thailandAddressData.custom_province = this.senderAddresses[id].province;
            if(this.senderAddresses[id].district)
                this.thailandAddressData.custom_district = this.senderAddresses[id].district;
            if(this.senderAddresses[id].subdistrict)
                this.thailandAddressData.custom_subdistrict = this.senderAddresses[id].subdistrict;
        },
        async loadMoreAddresses() {
            await this._loadMoreAddresses({type: 'senderAddresses'});
        },
        async searchAddresses() {
            await this._searchAddresses({type: 'senderAddresses', keyword: this.search_textbox});
        },
        async abortSearchAddresses() {
            await this._searchAddresses({type: 'senderAddresses', keyword: false});
            this.search_textbox = "";
        },
    },
    mounted() {
        if (this.hasSpecifiedSenderData) {
            this.form_sender_address_id = this.orderData.dealer_id;
        }
    },
}
</script>
<style scoped></style>