<template>
    <div v-show="isFacebookCheckboxPluginEnabled">
        <pre v-if="false">
userRef : {{ userRef }}
fbLoggedIn : {{ fbLoggedIn }}
isFacebookCheckboxPluginEnabled : {{ isFacebookCheckboxPluginEnabled }}
cpIsShowMessengerCheckbox : {{ cpIsShowMessengerCheckbox }}
cpIsMessengerCheckboxRendered : {{ cpIsMessengerCheckboxRendered }}
facebookCheckboxPluginUserRef : {{ facebookCheckboxPluginUserRef }}
facebookCheckboxPluginCheckboxState : {{ facebookCheckboxPluginCheckboxState }}
        </pre>
        <div class="just_flex align-center pb-2" style="gap: 10px;">
            <template v-if="cpIsShowMessengerCheckbox">
                <v-fade-transition leave-absolute>
                    <v-skeleton-loader :loading="true"
                                       v-if="!cpIsMessengerCheckboxRendered"
                                       min-width="200px"
                                       class="pt-4 pb-1 px-0 mx-0"
                                       type="text"></v-skeleton-loader>
                </v-fade-transition>
<!--                <div :style="(fbLoggedIn)?'':'max-height: 40px'"-->
                <div style="margin-left: -7px; width: 220px; max-width: 220px; overflow:hidden; max-height: 40px; margin-top: 5px;">
                    <div class="fb-messenger-checkbox"
                         :page_id="facebookCheckboxPluginPageId"
                         :messenger_app_id="facebookCheckboxPluginAppId"
                         opt-in-type="transactional"
                         :user_ref="facebookCheckboxPluginUserRef"
                         :ref="facebookCheckboxPluginRef"
                         allow_login="true"
                         skin="light"
                         :size="isDesktop?'standard':'standard'"
                         center_align="false"></div>
                </div>
                <div v-if="cpIsMessengerCheckboxRendered" style="position: absolute; z-index: 2; left: 47px;">
                    <span class="mr-2" style="color: rgba(0, 0, 0, 0.75);">{{ $t('แจ้งเตือนสถานะทาง') }} Messenger</span>
                    <svg width="16px"
                         height="16px"
                         fill="none"
                         viewBox="0 0 80 80"
                         version="1.1"
                         class="_7o43"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M40 .914C17.995.914.937 17.033.937 38.804c0 11.389 4.668 21.23 12.268 28.026a3.12 3.12 0 011.05 2.227l.212 6.95c.068 2.215 2.358 3.658 4.386 2.763l7.753-3.423a3.115 3.115 0 012.087-.153A42.602 42.602 0 0040 76.695c22.005 0 39.063-16.118 39.063-37.89C79.063 17.033 62.005.915 40 .915z"
                              fill="url(#paint0_radial)"></path>
                        <path fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M16.543 49.886L28.018 31.68a5.86 5.86 0 018.472-1.563l9.127 6.844c.837.628 1.989.625 2.823-.008L60.765 27.6c1.645-1.248 3.793.72 2.692 2.467L51.982 48.272a5.86 5.86 0 01-8.472 1.563l-9.127-6.845A2.344 2.344 0 0031.56 43l-12.325 9.354c-1.646 1.248-3.793-.72-2.692-2.467z"
                              fill="#fff"></path>
                        <defs>
                            <radialGradient id="paint0_radial"
                                            cx="0"
                                            cy="0"
                                            r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="rotate(-57.092 80.25 24.628) scale(85.1246)">
                                <stop stop-color="#09F"></stop>
                                <stop offset=".61"
                                      stop-color="#A033FF"></stop>
                                <stop offset=".935"
                                      stop-color="#FF5280"></stop>
                                <stop offset="1"
                                      stop-color="#FF7061"></stop>
                            </radialGradient>
                        </defs>
                    </svg>
                </div>
            </template>
            <template v-else>
                <v-checkbox v-model="fbCheckbox"
                            class="d-inline-block my-2 pt-0"
                            hide-details
                            :disabled="!fbCheckbox"
                            @change="unSubscribeMessenger()">
                    <template v-slot:label>
                        <span class="mr-2" style="color: rgba(0, 0, 0, 0.75);">{{ $t('แจ้งเตือนสถานะทาง') }} Messenger</span>
                        <svg width="16px"
                             height="16px"
                             fill="none"
                             viewBox="0 0 80 80"
                             version="1.1"
                             class="_7o43"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M40 .914C17.995.914.937 17.033.937 38.804c0 11.389 4.668 21.23 12.268 28.026a3.12 3.12 0 011.05 2.227l.212 6.95c.068 2.215 2.358 3.658 4.386 2.763l7.753-3.423a3.115 3.115 0 012.087-.153A42.602 42.602 0 0040 76.695c22.005 0 39.063-16.118 39.063-37.89C79.063 17.033 62.005.915 40 .915z"
                                  fill="url(#paint0_radial)"></path>
                            <path fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M16.543 49.886L28.018 31.68a5.86 5.86 0 018.472-1.563l9.127 6.844c.837.628 1.989.625 2.823-.008L60.765 27.6c1.645-1.248 3.793.72 2.692 2.467L51.982 48.272a5.86 5.86 0 01-8.472 1.563l-9.127-6.845A2.344 2.344 0 0031.56 43l-12.325 9.354c-1.646 1.248-3.793-.72-2.692-2.467z"
                                  fill="#fff"></path>
                            <defs>
                                <radialGradient id="paint0_radial"
                                                cx="0"
                                                cy="0"
                                                r="1"
                                                gradientUnits="userSpaceOnUse"
                                                gradientTransform="rotate(-57.092 80.25 24.628) scale(85.1246)">
                                    <stop stop-color="#09F"></stop>
                                    <stop offset=".61"
                                          stop-color="#A033FF"></stop>
                                    <stop offset=".935"
                                          stop-color="#FF5280"></stop>
                                    <stop offset="1"
                                          stop-color="#FF7061"></stop>
                                </radialGradient>
                            </defs>
                        </svg>
                    </template>
                </v-checkbox>
            </template>
            <v-tooltip v-if="!(cpIsShowMessengerCheckbox && !cpIsMessengerCheckboxRendered)"
                       class="d-inline-block"
                       max-width="300"
                       top>
                <template v-slot:activator="{ on }">
                    <div v-on="on"
                         class=" text-no-wrap">
                        <span v-if="false && isDesktop"
                              class="text--secondary text-caption mr-2">{{ $t('นี่คืออะไร') }}</span>
                        <v-icon dense>
                            mdi-help-circle-outline
                        </v-icon>
                    </div>
                </template>
                {{ $t('อัพเดทสถานะรายการสั่งซื้อนี้ (เช่น ร้านค้าจัดส่งสินค้าแล้ว) โดยระบบจะส่งข้อความสถานะให้คุณอัตโนมัติผ่านทาง') }} Facebook Messenger
            </v-tooltip>
        </div>
        <v-divider></v-divider>
    </div>
</template>
<script>
import {VDialog, VBottomSheet} from 'vuetify/lib';
import DebugIndicator from "@/components/DebugTools/DebugIndicator";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";

export default {
    name: "FbCheckboxPlugin",
    props: {},
    data(){
        return {
            fbLoggedIn: false,
            fbCheckbox: true,
        }
    },
    mixins: [
        MixinsHelper,
    ],
    components: {
        DebugIndicator,
        VDialog,
        VBottomSheet
    },
    computed: {
        ...mapGetters({
            isFacebookCheckboxPluginEnabled: 'order/isFacebookCheckboxPluginEnabled',
            facebookCheckboxPluginAppId: 'order/facebookCheckboxPluginAppId',
            facebookCheckboxPluginPageId: 'order/facebookCheckboxPluginPageId',
            facebookCheckboxPluginRef: 'order/facebookCheckboxPluginRef',
            facebookCheckboxPluginUserRef: 'order/facebookCheckboxPluginUserRef',
            facebookCheckboxPluginCheckboxState: 'order/facebookCheckboxPluginCheckboxState',
        }),
        ...mapState({
            orderData: state => state.order.orderData,
            shopData: state => state.order.shopData,
        }),
        cpIsMessengerCheckboxRendered(){
            // เพราะถ้ามีจะมี string 'checked' ไม่ก็ 'unchecked'
            return this.facebookCheckboxPluginCheckboxState ? true : false;
        },
        cpIsShowMessengerCheckbox(){
            if(!this.userRef || this.userRef == this.facebookCheckboxPluginUserRef){
                return true;
            }
            return false
        },
        userRef(){
            return this.orderData.messenger_optin_user_ref;
        }
    },
    methods: {
        ...mapMutations({
            mutationSetFacebookCheckboxPluginRenderedTime: 'order/setFacebookCheckboxPluginRenderedTime',
            mutationSetFacebookCheckboxPluginCheckboxState: 'order/setFacebookCheckboxPluginCheckboxState'
        }),
        ...mapActions({
            actionSetMessengerOptInUserRef: 'order/setMessengerOptInUserRef',
            actionFacebookCheckboxPluginConfirmOptIn: 'order/facebookCheckboxPluginConfirmOptIn'
        }),
        async subscribeMessenger(){
            let payload = {};
            // console.log('subscribe run');
            if(this.facebookCheckboxPluginCheckboxState === 'checked'){
                // console.log('checked');
                payload.user_ref = this.facebookCheckboxPluginUserRef;
                // console.log(payload.user_ref);
                await this.actionSetMessengerOptInUserRef(payload);
                // console.log('set userref as : ', this.orderData.messenger_optin_user_ref);
                // if(!this.isLocalhost){
                await this.actionFacebookCheckboxPluginConfirmOptIn();
                // }

            }

        },
        async unSubscribeMessenger(){

            let payload = {};
            payload.user_ref = null;
            // console.log(payload.user_ref);
            await this.actionSetMessengerOptInUserRef(payload);
            if(window.FB){
                window.FB.XFBML.parse();
            }
        },
    },
    watch: {
        facebookCheckboxPluginCheckboxState(newValue){
            if(newValue == 'checked'){
                this.fbLoggedIn = true;
            }

        }
    },
    beforeMount(){
        if(window.FB && this.isFacebookCheckboxPluginEnabled){
            this.mutationSetFacebookCheckboxPluginRenderedTime((new Date()).getTime());
            this.mutationSetFacebookCheckboxPluginCheckboxState(null);
        }
    },
    mounted(){
        if(window.FB && this.isFacebookCheckboxPluginEnabled){
            window.FB.XFBML.parse();
        }
    }
}
</script>