<!--suppress XmlDuplicatedId -->
<template>
    <div class="stepContent shippingMethod">
        <debug-indicator file-name="src/components/order/ShippingMethod.vue"/>
        <template v-if="false">
            <pre>{{ shipping_types_that_not_price_false }}</pre>
            <div>editAddressId : {{ editAddressId }}</div>
            <div>receiver_id : {{ orderData.receiver_id }}</div>
            <div>dealer_id : {{ orderData.dealer_id }}</div>
            <div>contact_key : {{ orderData.receiver.contact_key }}</div>
            <div>isDropshipActive : {{ isDropshipActive }}</div>
            <div>is_dummy_receiver_contact : {{ is_dummy_receiver_contact }}</div>
            <div>form_shipping_address_id : {{ form_shipping_address_id }}</div>
            <div>form_shipping_type_id : {{ form_shipping_type_id }}</div>
            <div>orderData.order_status : {{ orderData.order_status }}</div>
            <div>shippingStep : {{ shippingStep }}</div>
            <div>shippingAddresses : {{ shippingAddresses }}</div>
            <div>orderData.skip_receiver_flag : {{ orderData.skip_receiver_flag }}</div>
            <div>orderData.skip_payment_flag : {{ orderData.skip_payment_flag }}</div>
            <div>orderData.can_edit_buyer_info : {{ orderData.can_edit_buyer_info }}</div>
            <div>canEditShippingType : {{ canEditShippingType }}</div>
            <div>canEditReceiverContact : {{ canEditReceiverContact }}</div>
            <div>isPass : {{ isPass }}</div>
            <div>orderData.receiver_id : {{ orderData.receiver_id }}</div>
            <div>isExpanded : {{ isExpanded }}</div>
            <div>isShippingIsLastStep : {{ isShippingIsLastStep }}</div>
            <div>isRequiredReceiver : {{ isRequiredReceiver }}</div>
            <div>isReceiverInfoInFormReady : {{ isReceiverInfoInFormReady }}</div>
            <div>isAddressEditorFormShow : {{ isAddressEditorFormShow }}</div>
            <div>canSkipReceiver : {{ canSkipReceiver }}</div>
            <div>isSkippedReceiver : {{ isSkippedReceiver }}</div>
            <div>isPassReceiverContact : {{ isPassReceiverContact }}</div>
            <div>isAddressEdit : {{ isAddressEdit }}</div>
            <div>isShipOversea : {{ isShipOversea }}</div>
            <div>isOrderNoShipping : {{ isOrderNoShipping }}</div>
            <div>isOrderNoShippingType : {{ isOrderNoShippingType }}</div>
            <div>isForceShippingPrice : {{ isForceShippingPrice }}</div>
            <div>hasPickupType : {{ hasPickupType }}</div>
        </template>
        <v-scroll-y-transition hide-on-leave>
            <!-- Step ที่อยู่ ขณะขยาย -->
            <!-- ที่ใส่ @submit เพราะจะ trigger ให้  save address ซึ่งจะมีแค่ ขั้น  receiver เท่านั้นที่มี submit button -->
            <v-form v-if="isExpanded"
                    @submit.prevent="submitOrderReceiver"
                    autocomplete="on"
                    ref="form_shipping"
                    lazy-validation>
                <debug-indicator file-name="src/components/order/ShippingMethod.vue"
                                 detail="v-if=isExpanded"/>
                <!-- ยังไม่ผ่านขั้นตอน ข้อมูลผู้ซื้อ -->
                <template v-if="!isPassStepBuyerInfo">
                    <debug-indicator file-name="src/components/order/ShippingMethod.vue"
                                     detail="v-if=!isPassStepBuyerInfo"/>
                    <v-alert @click="gotoPreviousStep()"
                             style="cursor: pointer;"
                             color="primary"
                             class="mb-8"
                             type="info">
                        <div class="just_flex">
                            <span>{{ $t('โปรดระบุข้อมูลติดต่อก่อนนะคะ') }}</span>
                            <v-icon>mdi-arrow-up</v-icon>
                        </div>
                    </v-alert>
                </template>
                <!-- แสดงกล่องที่ไว้ให้ตั้งที่อยู่จัดส่ง /// ถ้ายังไม่ผ่านขั้นตอน ข้อมูลผู้ซื้อ ให้ disabled กล่อง input ไว้ก่อน -->
                <div :class="!isPassStepBuyerInfo?'disabled':''">
                    <!-- สำหรับไม่มีการจัดส่ง -->
                    <template v-if="isOrderNoShipping">
                        <v-radio-group value="no_shipping">
                            <v-card id="addressList"
                                    class="mx-auto"
                                    outlined
                                    :disabled="!isPassStepBuyerInfo">
                                <v-list-item-group top>
                                    <div>
                                        <template>
                                            <label>
                                                <v-list-item>
                                                    <v-list-item-action>
                                                        <v-radio value="no_shipping"></v-radio>
                                                    </v-list-item-action>
                                                    <v-list-item-content>
                                                        <div class="just_flex align-start">
                                                            <div>
                                                                <v-list-item-title>
                                                                    <div>
                                                                        {{ $t('ไม่มีการจัดส่ง') }}
                                                                    </div>
                                                                </v-list-item-title>
                                                                <v-list-item-subtitle>
                                                                    {{ $t('รายการสั่งซื้อนี้ถูกกำหนดให้ไม่มีการจัดส่งผ่านผู้ให้บริการขนส่ง') }}
                                                                </v-list-item-subtitle>
                                                            </div>
                                                        </div>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </label>
                                        </template>
                                    </div>
                                </v-list-item-group>
                            </v-card>
                            <div class="submitArea ready">
                                <template v-if="!isShippingIsLastStep">
                                    <v-btn class="backButton"
                                           text
                                           height="52"
                                           @click="gotoPreviousStep()">
                                        <div class="icon-chevron-left"></div>
                                        <template v-if="orderData.order_status === 'wait_order'">
                                            {{ $t('กลับไปข้อมูลติดต่อ') }}
                                        </template>
                                        <template v-else>
                                            {{ $t('กลับไปชำระเงิน') }}
                                        </template>
                                    </v-btn>
                                </template>
                                <template v-else>
                                    <div class="needDiv"></div>
                                </template>
                                <div @click="validate">
                                    <v-btn x-large
                                           block
                                           depressed
                                           @click="gotoNextStep()"
                                           color="primary">
                                        <template v-if="isShippingIsLastStep">
                                            {{ $t('ยืนยัน') }}
                                        </template>
                                        <template v-else>
                                            {{ $t('ถัดไป') }}
                                        </template>
                                    </v-btn>
                                </div>
                            </div>
                        </v-radio-group>
                    </template>
                    <!-- สำหรับมีการจัดส่งตามปกติ -->
                    <template v-else>
                        <div id="addressStep1"></div>
                        <template v-if="hasPickupType">
                             <div class="mb-3">
                                {{ $t('เลือกวิธีการรับสินค้าที่คุณสะดวก') }}
                            </div>
                            <v-radio-group mandatory
                                           row
                                           v-model="isPickupType">
                                <div class="just_flex" style="gap: 1rem; width: 100%;">
                                    <v-card outlined class="px-5 py-4" :class="hasPickupTypeButNoShippingType?'disabled':''">
                                        <v-radio :label="$t('จัดส่งตามที่อยู่ของฉัน')"
                                                 :disabled="hasPickupTypeButNoShippingType"
                                                 :value="0"
                                                 class="mr-0"></v-radio>
                                    </v-card>
                                    <v-card outlined class="px-5 py-4">
                                        <v-radio :label="$t('รับสินค้าด้วยตนเอง')"
                                        :value="1" class="mr-0"></v-radio>
                                    </v-card>
                                </div>
                            </v-radio-group>
                        </template>
                        <v-stepper v-model="shippingStep"
                                   vertical>
                            <div v-if="canEditShippingType" class="just_flex">
                                <v-stepper-step
                                                :complete="shippingStep > 1"
                                                :edit-icon="(isPassReceiverContact || isSkippedReceiver)?'$complete':'$edit'"
                                                step="1"
                                                editable>
                                    <template v-if="isAddressEditorFormShow">
                                        <div>
                                            {{ $t('กรอกข้อมูลผู้รับสินค้า') }}
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div v-if="shippingStep == 1">
                                            {{ $t('เลือกที่อยู่จัดส่งพัสดุของคุณ') }}
                                        </div>
                                        <div v-else>
                                            <template v-if="orderData.receiver_id && shippingAddresses[orderData.receiver_id]">
                                                <div class="important_data">{{ orderData.receiver.name }}
                                                    <template v-if="orderData.receiver.telephone">
                                                        <span class="minor"> · </span>
                                                        <div class="d-inline-block">{{ orderData.receiver.telephone }}</div>
                                                    </template>
                                                    <template v-else-if="orderData.receiver.mobile">
                                                        <span class="minor"> · </span>
                                                        <div class="d-inline-block">{{ mobile_format(orderData.receiver.mobile) }}</div>
                                                    </template>
                                                </div>
                                                <div class="minor mt-2">
                                                    {{ address_format(orderData.receiver) }}
                                                </div>
                                            </template>
                                            <template v-else-if="isPickupType && canSkipReceiver">
                                                <b>{{ $t('เลือกรับสินค้าด้วยตนเองแล้ว') }}</b>
                                            </template>
                                            <template v-else-if="isSkippedReceiver">
                                                <b>{{ $t('คุณเลือกกรอกข้อมูลที่อยู่ภายหลังแล้ว') }}</b>
                                            </template>
                                            <template v-else>
                                                {{ $t('กรุณาเลือกที่อยู่จัดส่ง') }}
                                            </template>
                                        </div>
                                    </template>
                                </v-stepper-step>
                                <template v-if="showSkipReceiverButton">
                                    <v-btn v-if="!isSkippedReceiver || isAddressEditorFormShow || shippingStep == 1"
                                           :disabled="!canSkipReceiver"
                                           @click="skipOrderReceiver"
                                           color="primary"
                                           depressed
                                           text>
                                        {{ $t('กรอกภายหลัง') }}
                                    </v-btn>
                                </template>
                            </div>
                            <!-- เลือกที่อยู่จัดส่ง -->
                            <v-stepper-content step="1"
                                               :style="!canEditShippingType?'margin-left: 0; padding-left:0':''">
                                <v-alert v-if="isPickupType"
                                         text
                                         type="error"
                                         class="mb-6"
                                         color="primary">
                                    <span>{{ $t('ที่อยู่สำหรับกรณีที่ไม่เข้ามารับสินค้าตามที่กำหนด')}}</span>
                                </v-alert>
                                <v-scroll-y-transition hide-on-leave>
                                    <div v-if="!isAddressEditorFormShow">
                                        <template v-if="isDropshipActive">
                                            <sender-address :is-expanded="isExpanded"></sender-address>
                                        </template>
                                        <template v-if="gointer_enabled">
                                            <v-select class="mt-2 my-1"
                                                      ref="shippingOverseaSelect"
                                                      v-model="isShipOversea"
                                                      :items="itemsSelectShippingOversea"
                                                      item-text="text"
                                                      item-value="value"
                                                      :key="isShipOversea"
                                                      :label="$t('ประเภทการจัดส่ง')"
                                                      outlined>
                                                <template v-slot:selection="{ item }">
                                                    <v-list-item style="margin: 0; padding: 0 10px;">
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ item.text }}</v-list-item-title>
                                                            <v-list-item-subtitle v-if="!orderData.receiver_id && only_this_international_country.length">
                                                                <template v-if="item.value">{{ only_this_international_country_text }}</template>
                                                                <template v-else>Thailand</template>
                                                            </v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </template>
                                                <template v-slot:item="{ index, item }">
                                                    <v-list-item  @click="changeShipOversea(item)">
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ item.text }}</v-list-item-title>
                                                            <v-list-item-subtitle v-if="!orderData.receiver_id && only_this_international_country.length">
                                                                <template v-if="item.value">{{ only_this_international_country_text }}</template>
                                                                <template v-else>Thailand</template>
                                                            </v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </template>
                                            </v-select>
                                        </template>
                                        <v-alert v-if="shipping_types_to_display.length == 0 && !orderData.receiver_id" class="mb-6"
                                                 text
                                                 color="primary"
                                                 dense>
                                            <v-row align="center">
                                                <v-col class="grow">
                                                    <v-icon class="red--text">mdi-close</v-icon> <span style="font-weight: normal">{{ $t('ขออภัยค่ะ ไม่มีวิธีจัดส่งที่รองรับ') }}</span>
                                                </v-col>
                                            </v-row>
                                        </v-alert>
                                        <template v-else>
                                            <template v-if="isDropshipActive">
                                                <v-radio-group v-model="form_shipping_address_is_dealer"
                                                               row>
                                                    <v-radio v-for="item in itemsSelectShippingAddressForDealer"
                                                             :key="item.text"
                                                             :label="item.text"
                                                             :value="item.value"></v-radio>
                                                </v-radio-group>
                                            </template>
                                            <template v-if="use_this_allMoreThanPageLimit">
                                                <v-text-field class="mt-1"
                                                              clearable
                                                              v-disabled-icon-focus
                                                              outlined
                                                              v-model.trim="search_textbox"
                                                              @keypress.enter="searchAddressesEnter($event)"
                                                              :disabled="!isPassStepBuyerInfo">
                                                    <template v-slot:label>
                                                        {{ $t('ค้นหาที่อยู่ ด้วยชื่อ เบอร์โทร หรืออีเมล') }}
                                                    </template>
                                                    <v-btn style="margin-top: -18px !important;"
                                                           x-large
                                                           depressed
                                                           class="primary--text"
                                                           @click="searchAddressesEnter($event)"
                                                           slot="append-outer">
                                                        {{ $t('ค้นหา') }}
                                                    </v-btn>
                                                </v-text-field>
                                            </template>
                                            <debug-indicator file-name="src/components/order/ShippingMethod.vue"
                                                             detail="v-if=!isAddressEditorFormShow"/>
                                            <v-radio-group hide-details="auto"
                                                           :rules="addressRules"
                                                           v-model="form_shipping_address_id">
                                                <v-card id="addressList"
                                                        class="mx-auto"
                                                        outlined
                                                        :disabled="!isPassStepBuyerInfo">
                                                    <v-list-item-group top>
                                                        <div v-for="index in sortedForAllAddresses"
                                                             :key="shippingAddresses[index].contact_id"
                                                             :value="shippingAddresses[index].contact_id">
                                                            <template v-if="showThisAddress(shippingAddresses[index])">
                                                                <label v-show="index !== 0 && (isShowAllAddress || !form_shipping_address_id || form_shipping_address_id == shippingAddresses[index].contact_id)"
                                                                       @click="hideOtherAddress()">
                                                                    <v-list-item>
                                                                        <v-list-item-action>
                                                                            <v-radio color="primary"
                                                                                     :value="shippingAddresses[index].contact_id"></v-radio>
                                                                        </v-list-item-action>
                                                                        <v-list-item-content>
                                                                            <div class="just_flex align-start">
                                                                                <div>
                                                                                    <v-list-item-title>
                                                                                        <div>
                                                                                            <div class="d-inline-block mr-2">
                                                                                                {{ shippingAddresses[index].name }}
                                                                                            </div>
                                                                                            <div class="d-inline-block">
                                                                                                {{ shippingAddresses[index].telephone ? shippingAddresses[index].telephone : mobile_format(shippingAddresses[index].mobile) }}
                                                                                            </div>
                                                                                        </div>
                                                                                    </v-list-item-title>
                                                                                    <v-list-item-subtitle class="mt-1">
                                                                                        {{ address_format(shippingAddresses[index]) }}
                                                                                        <div v-if="isShipOversea"
                                                                                             v-text="showCountryName(shippingAddresses[index])"></div>
                                                                                    </v-list-item-subtitle>
                                                                                </div>
                                                                                <a class="ml-2 d-inline-block font-weight-normal primary--text"
                                                                                   @click="editAddress(shippingAddresses[index].contact_id)"
                                                                                   :style="(!isShowAllAddress)?'opacity: 0;':''"
                                                                                   :class="{disabled : !isShowAllAddress}">{{ $t('แก้ไข') }}</a>
                                                                            </div>
                                                                        </v-list-item-content>
                                                                    </v-list-item>
                                                                </label>
                                                                <v-divider v-show="index !== 0 && (isShowAllAddress || form_shipping_address_id == shippingAddresses[index].contact_id)"></v-divider>
                                                            </template>
                                                        </div>
                                                        <div v-if="isShowAllAddress && !isSearching">
                                                            <v-list-item @click="addNewAddress()">
                                                                <v-list-item-action>
                                                                    <v-radio color="primary"
                                                                             :value="-1"></v-radio>
                                                                </v-list-item-action>
                                                                <v-list-item-content>
                                                                    <v-list-item-title style="margin-bottom: 0">
                                                                        {{ $t('เพิ่มที่อยู่ใหม่') }}
                                                                    </v-list-item-title>
                                                                </v-list-item-content>
                                                                <v-list-item-action>
                                                                    <v-icon>mdi-chevron-right</v-icon>
                                                                </v-list-item-action>
                                                            </v-list-item>
                                                            <v-divider></v-divider>
                                                        </div>
                                                        <div v-if="(!isShowAllAddress || isSearching) && sortedReceiverAddresses.length > 1">
                                                            <label>
                                                                <v-list-item @click="showAllAddress()">
                                                                    <v-list-item-action>
                                                                        <v-icon>mdi-chevron-down</v-icon>
                                                                    </v-list-item-action>
                                                                    <v-list-item-content>
                                                                        <v-list-item-title style="margin-bottom: 0">
                                                                            <span style="font-weight: normal">{{ $t('แสดงรายการที่อยู่ทั้งหมด') }}</span>
                                                                        </v-list-item-title>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </label>
                                                        </div>
                                                        <div v-else-if="use_this_hasMore">
                                                            <label>
                                                                <v-list-item @click="loadMoreAddresses()">
                                                                    <v-list-item-action>
                                                                        <v-icon>mdi-chevron-down</v-icon>
                                                                    </v-list-item-action>
                                                                    <v-list-item-content>
                                                                        <v-list-item-title style="margin-bottom: 0">
                                                                            <span style="font-weight: normal">{{ $t('โหลดรายการที่อยู่เพิ่มเติม') }}...</span>
                                                                        </v-list-item-title>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </label>
                                                        </div>
                                                    </v-list-item-group>
                                                </v-card>
                                            </v-radio-group>
                                        </template>
                                    </div>
                                </v-scroll-y-transition>
                                <v-scroll-y-transition hide-on-leave>
                                    <div v-if="isAddressEditorFormShow">
                                        <debug-indicator file-name="src/components/order/ShippingMethod.vue"
                                                         detail="v-if=isAddressEditorFormShow"/>
                                        <v-alert v-if="sortedReceiverAddresses.length > 0"
                                                 text
                                                 type="info"
                                                 class="mb-6"
                                                 color="#666">
                                            <div class="just_flex">
                                                <span>{{ editAddressId > 0 ? $t('คุณกำลังแก้ไขที่อยู่เดิม') : $t('คุณกำลังเพิ่มที่อยู่ใหม่') }}</span>
                                                <template v-if="sortedReceiverAddresses.length > 0">
                                                    <a class="primary--text"
                                                       @click="closeAddressEditorForm(true); showAllAddress();">{{ $t('เปลี่ยน') }}</a>
                                                </template>
                                            </div>
                                        </v-alert>
                                        <div v-else
                                             class="pb-2"></div>
                                    </div>
                                </v-scroll-y-transition>
                                <template v-if="isAddressEditorFormShow">
                                    <template v-if="gointer_enabled">
                                        <v-select class="mt-2 my-1"
                                                  ref="shippingOverseaSelect"
                                                  v-model="isShipOversea"
                                                  :items="itemsSelectShippingOversea"
                                                  item-text="text"
                                                  item-value="value"
                                                  :key="isShipOversea"
                                                  :label="$t('ประเภทการจัดส่ง')"
                                                  outlined>
                                            <template v-slot:selection="{ item }">
                                                <v-list-item style="margin: 0; padding: 0 10px;">
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                                                        <v-list-item-subtitle v-if="!orderData.receiver_id && only_this_international_country.length">
                                                            <template v-if="item.value">{{ only_this_international_country_text }}</template>
                                                            <template v-else>Thailand</template>
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </template>
                                            <template v-slot:item="{ index, item }">
                                                <v-list-item  @click="changeShipOversea(item)">
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                                                        <v-list-item-subtitle v-if="!orderData.receiver_id && only_this_international_country.length">
                                                            <template v-if="item.value">{{ only_this_international_country_text }}</template>
                                                            <template v-else>Thailand</template>
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </template>
                                        </v-select>
                                    </template>
                                    <v-alert v-if="shipping_types_to_display.length == 0 && !orderData.receiver_id" class="mb-6"
                                             text
                                             color="primary"
                                             dense>
                                        <v-row align="center">
                                            <v-col class="grow">
                                                <v-icon class="red--text">mdi-close</v-icon> <span style="font-weight: normal">{{ $t('ขออภัยค่ะ ไม่มีวิธีจัดส่งที่รองรับ') }}</span>
                                            </v-col>
                                        </v-row>
                                    </v-alert>
                                    <template v-else>
                                        <v-textarea :label="$t('เลขที่ อาคาร ซอย ถนน และรายละเอียดอื่นๆ')"
                                                    auto-grow
                                                    autocomplete="street-address"
                                                    outlined
                                                    rows="3"
                                                    class="mb-3"
                                                    ref="receiverAddress"
                                                    :rules="isShipOversea?internationalRules:commonRules"
                                                    @blur="splitAddress"
                                                    v-model.trim="address">
                                            <template v-slot:append
                                                      v-if="!isShipOversea && editAddressId == -1">
                                                <v-tooltip top
                                                           right
                                                           max-width="320">
                                                    <template v-slot:activator="{ on }">
                                                        <div style="cursor: pointer"
                                                             @click="canSplitAddress = !canSplitAddress"
                                                             v-on="on">
                                                            <v-icon :class="(canSplitAddress)?'primary--text':''">mdi-auto-fix</v-icon>
                                                        </div>
                                                    </template>
                                                    <span v-if="canSplitAddress"
                                                          class="green--text text--lighten-1">{{ $t('พร้อมแยกที่อยู่อัตโนมัติ') }}</span>
                                                    <span v-else
                                                          class="orange--text ">{{ $t('หยุดแยกที่อยู่อัตโนมัติ') }} : {{ $t('คลิกเพื่อเปิดอีกครั้ง') }}</span>
                                                    <br> {{ $t('คุณสามารถวางชื่อ-ที่อยู่ของคุณทั้งหมดลงในช่องนี้ ระบบจะคัดแยกข้อมูลให้คุณอัตโนมัติ (กรุณาเว้นบรรทัดระหว่างชื่อกับที่อยู่)') }}
                                                </v-tooltip>
                                            </template>
                                        </v-textarea>
                                        <template v-if="!isShipOversea">
                                            <thailand-address ref="thailandAddress"
                                                              :locale="$i18n.locale"
                                                              v-model="thailandAddressData"></thailand-address>
                                        </template>
                                        <template v-else>
                                            <international-address ref="internationalAddress"
                                                                   :countries="only_this_international_country"
                                                                   v-model="internationalAddressData"></international-address>
                                        </template>
                                        <v-divider dark></v-divider>
                                        <v-text-field ref="receiverName"
                                                      :label="$t('ชื่อสกุลผู้รับ')"
                                                      :rules="isShipOversea?internationalRules:commonRules"
                                                      validate-on-blur
                                                      autocomplete="name"
                                                      clearable
                                                      v-disabled-icon-focus
                                                      outlined
                                                      v-model.trim="name"></v-text-field>
                                        <div class="flex_align_start" style="gap: 20px;">
                                            <div class="needDiv">
                                                <v-text-field ref="receiverMobileNumber"
                                                              :label="$t('เบอร์โทรผู้รับ')"
                                                              :rules="telephoneRules"
                                                              @change="onlyNumberInput"
                                                              autocomplete="tel"
                                                              type="tel"
                                                              validate-on-blur
                                                              pattern="\d*"
                                                              maxlength=30
                                                              clearable
                                                              outlined
                                                              v-model.trim="mobileNumber"></v-text-field>
                                            </div>
                                            <div v-if="false" class="needDiv">
                                                <v-text-field
                                                        :label="$t('ต่อ') + ' (' + $t('ถ้ามี') + ')'"
                                                        @change="onlyNumberInput"
                                                        autocomplete="tel"
                                                        type="tel"
                                                        validate-on-blur
                                                        pattern="\d*"
                                                        style="max-width:150px;"
                                                        maxlength=10
                                                        clearable
                                                        outlined
                                                        v-model.trim="extNumber"></v-text-field>
                                            </div>
                                        </div>
                                    </template>
                                </template>
                                <!--                                กรณีออเดอร์มีการยืนยันชำระเงินไปแล้ว หากใส่ที่อยู่แล้วจะไปรอจัดส่งเลย-->
                                <v-alert type="info"
                                         text
                                         v-if="isShippingIsLastStep && orderData.order_payment">
                                    {{ $t('กรุณาตรวจทานข้อมูลนี้อีกครั้ง คุณจะไม่สามารถแก้ไขได้ในภายหลัง') }}
                                </v-alert>
                                <div class="submitArea"
                                     :class="!isReceiverNextButtonDisabled?'ready':''">
                                    <template v-if="!isShippingIsLastStep">
                                        <v-btn class="backButton"
                                               text
                                               height="52"
                                               @click="gotoPreviousStep()">
                                            <div class="icon-chevron-left"></div>
                                            <template v-if="orderData.order_status === 'wait_order'">
                                                {{ $t('กลับไปข้อมูลติดต่อ') }}
                                            </template>
                                            <template v-else>
                                                {{ $t('กลับไปชำระเงิน') }}
                                            </template>
                                        </v-btn>
                                    </template>
                                    <template v-else>
                                        <div class="needDiv"></div>
                                    </template>
                                    <div @click="validate">
                                        <v-btn x-large
                                               block
                                               depressed
                                               type="submit"
                                               :disabled="isReceiverNextButtonDisabled"
                                               color="primary">
                                            <template v-if="isShippingIsLastStep">
                                                {{ $t('ยืนยัน') }}
                                            </template>
                                            <template v-else>
                                                {{ $t('ถัดไป') }}
                                            </template>
                                        </v-btn>
                                    </div>
                                </div>
                                <div class="mt-4"></div>
                            </v-stepper-content>
                            <template v-if="canEditShippingType">
                                <div id="addressStep2"></div>
                                <v-stepper-step :complete="shippingStep > 2"
                                                step="2"
                                                editable>
                                    <div class="just_flex">
                                        <span v-if="isPickupType">{{ $t('เลือกวิธีการเข้ารับ') }}</span>
                                        <span v-else>{{ $t('เลือกวิธีการจัดส่ง') }}</span>
                                        <span class="opacity50" v-if="false && shippingStep > 2">{{ shippingAddresses[form_shipping_address_id].zipcode }}</span>
                                    </div>
                                </v-stepper-step>
                                <v-stepper-content step="2">
                                    <v-alert text
                                             icon="mdi-package-variant-closed"
                                             prominent
                                             light
                                             id="prepareAlert"
                                             class="mb-6"
                                             color="primary"
                                             type="info"
                                             :dense="(!weekendFlag && !holidayDate)">
                                        {{ $t('ระยะเวลาแพ็คสินค้า') }}
                                        <b>{{ prepareDayWithAdditional }}</b> {{ weekendFlag ? $t('วันทำการ') : $t('วัน') }}
                                        <ul v-if="weekendFlag || showHolidayDate"
                                            class="p-0 m-0 mt-1"
                                            style="list-style: none; font-size: 0.8em;">
                                            <li v-if="weekendFlag">
                                                · {{ $t('หยุดวันเสาร์-อาทิตย์ และวันหยุดนักขัตฤกษ์') }}
                                            </li>
                                            <li v-if="showHolidayDate">
                                                · {{ $t('หยุดเพิ่มเติม') }} : {{ $d(new Date(holidayDate[0].replace(/-/g, "/")), 'short') }} - {{ $d(new Date(holidayDate[1].replace(/-/g, "/")), 'short') }}
                                            </li>
                                        </ul>
                                    </v-alert>
                                    <!-- สำหรับการจัดส่งแยกพิเศษ (ทุกสินค้า) ไม่ต้องเลือกวิธีจัดส่ง -->
                                    <template v-if="isOrderNoShippingType">
                                        <v-radio-group value="no_shipping">
                                            <v-card id="addressList"
                                                    class="mx-auto"
                                                    outlined
                                                    :disabled="!isPassStepBuyerInfo">
                                                <v-list-item-group top>
                                                    <div>
                                                        <template>
                                                            <label>
                                                                <v-list-item>
                                                                    <v-list-item-action>
                                                                        <v-radio value="no_shipping"></v-radio>
                                                                    </v-list-item-action>
                                                                    <v-list-item-content>
                                                                        <div class="just_flex align-start">
                                                                            <div>
                                                                                <v-list-item-title>
                                                                                    <div>
                                                                                        {{ $t('จัดส่งแยกพิเศษ') }}
                                                                                    </div>
                                                                                </v-list-item-title>
                                                                            </div>
                                                                        </div>
                                                                    </v-list-item-content>
                                                                    <v-list-item-action class="flex_end costColumn">
                                                                        <div class="">
                                                                            <span class="baht">฿</span>
                                                                            <template v-if="isForceShippingPrice">{{ number_format(orderData.force_shipping_price, 2) }}</template>
                                                                            <template v-else>{{ number_format(orderData.price.shipping, 2) }}</template>
                                                                        </div>
                                                                    </v-list-item-action>
                                                                </v-list-item>
                                                            </label>
                                                        </template>
                                                    </div>
                                                </v-list-item-group>
                                            </v-card>
                                            <div class="submitArea ready">
                                                <template v-if="!isShippingIsLastStep">
                                                    <v-btn class="backButton"
                                                           text
                                                           height="52"
                                                           @click="gotoPreviousStep()">
                                                        <div class="icon-chevron-left"></div>
                                                        <template v-if="orderData.order_status === 'wait_order'">
                                                            {{ $t('กลับไปข้อมูลติดต่อ') }}
                                                        </template>
                                                        <template v-else>
                                                            {{ $t('กลับไปชำระเงิน') }}
                                                        </template>
                                                    </v-btn>
                                                </template>
                                                <template v-else>
                                                    <div class="needDiv"></div>
                                                </template>
                                                <div @click="validate">
                                                    <v-btn x-large
                                                           block
                                                           depressed
                                                           @click="gotoNextStep()"
                                                           color="primary">
                                                        <template v-if="isShippingIsLastStep">
                                                            {{ $t('ยืนยัน') }}
                                                        </template>
                                                        <template v-else>
                                                            {{ $t('ถัดไป') }}
                                                        </template>
                                                    </v-btn>
                                                </div>
                                            </div>
                                        </v-radio-group>
                                    </template>
                                    <template v-else>
                                        <v-combobox v-if="isPickupType"
                                                ref="comboBox"
                                                    class="mt-2 my-1"
                                                  v-model.trim="filterArea"
                                                    clearable
                                                    @keydown.enter="searchPickupMethodEnter"
                                                    prepend-inner-icon="mdi-magnify"
                                                  :items="shipping_pickup_delivery_areas"
                                                  :label="$t('ค้นหา')"
                                                  outlined>
                                            <v-btn style="margin-top: -18px !important;"
                                                   x-large
                                                   depressed
                                                   class="primary--text"
                                                   @click="searchPickupMethodEnter()"
                                                   slot="append-outer">
                                                {{ $t('ค้นหา') }}
                                            </v-btn>
                                        </v-combobox>
                                        <template v-if="isPickupType && shipping_types_to_display.length > 3">
                                            <v-autocomplete ref="informPaymentBank"
                                                      :label="$t('คลิกเลือกสาขาเข้ารับ')"
                                                      :items="shipping_types_to_display"
                                                            prepend-inner-icon="mdi-map-marker"
                                                      item-text="name"
                                                      item-value="id"
                                                      outlined
                                                      class="mb-6"
                                                      :menu-props="{closeOnContentClick: true, maxHeight: 330}"
                                                      hide-details="auto"
                                                      :rules="[v => !!v || $t('กรุณาเลือกวิธีการเข้ารับด้วยนะคะ')]"
                                                      v-model="form_shipping_type_id">
                                                <template v-slot:selection="{ item, index }">
                                                    <v-list-item style="margin: 0; padding: 0 10px;">
                                                        <v-list-item-content>
                                                            {{ item.name }}
                                                        </v-list-item-content>
                                                        <v-list-item-action class="flex_end">
                                                            <div class="minor">{{ prepareDay + item.additional_prepare_day }} {{ weekendFlag ? $t('วันทำการ') : $t('วัน') }}</div>
                                                        </v-list-item-action>
                                                    </v-list-item>
                                                </template>
                                                <template v-slot:item="{ index, item }">
                                                    <v-list-item @click="form_shipping_type_id = item.id">
                                                        <v-list-item-content>
                                                            <v-list-item-title>
                                                                <div class="just_flex flex-wrap">
                                                                    <span>{{ item.name }}</span>
                                                                </div>
                                                            </v-list-item-title>
                                                            <v-list-item-subtitle v-if="item.description">
                                                                {{ item.description }}
                                                            </v-list-item-subtitle>

                                                            <v-list-item-subtitle v-if="item.delivery_area && !(orderData.receiver_id && shippingAddresses[orderData.receiver_id])">
                                                                <div class="text-caption">{{ $t(item.delivery_area) }}</div>
                                                            </v-list-item-subtitle>
                                                        </v-list-item-content>
                                                        <v-list-item-action class="flex_end" style="justify-content: flex-start">
                                                            <div style="line-height: 1.5">
                                                                <template v-if="item.format == 'custom'">
                                                                    {{ $t('คิดค่าส่งภายหลัง') }}
                                                                </template>
                                                                <template v-else-if="(isShippingTypeForceShippingPrice(item.id) && orderData.force_shipping_price <= 0 ) || item.price <= 0">
                                                                    {{ $t('ฟรี') }}
                                                                </template>
                                                                <template v-else>
                                                                    <template v-if="isShippingTypeForceShippingPrice(item.id)">
                                                                        <span class="baht">฿</span>{{ number_format(orderData.force_shipping_price, 2) }}
                                                                    </template>

                                                                    <template v-else-if="item.price <= 0">{{ $t('ฟรี') }}</template>
                                                                    <template v-else><span class="baht">฿</span>{{ number_format(item.price, 2) }}</template>
                                                                </template>
                                                            </div>
                                                            <div  class="minor">
                                                                <v-tooltip bottom>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <div v-bind="attrs"
                                                                             v-on="on">
                                                                            <template v-if="isPickupType">
                                                                                {{ prepareDay + item.additional_prepare_day }} {{ weekendFlag ? $t('วันทำการ') : $t('วัน') }}
                                                                            </template>
                                                                            <template v-else>
                                                                                {{ item.period }}
                                                                            </template>
                                                                        </div>
                                                                    </template>
                                                                    <span>
                                                                        <template v-if="isPickupType">
                                                                            {{ $t('ระยะเวลาแพ็คสินค้า') }}
                                                                        </template>
                                                                        <template v-else>
                                                                            {{ $t('ระยะเวลาขนส่ง') }}
                                                                        </template>
                                                                    </span>
                                                                </v-tooltip>

                                                            </div>
                                                        </v-list-item-action>
                                                    </v-list-item>
                                                </template>
                                            </v-autocomplete>
                                        </template>
                                        <template v-else>
                                            <v-radio-group hide-details="auto"
                                                       :rules="[v => !!v || $t('กรุณาเลือกวิธีจัดส่งด้วยนะคะ')]"
                                                       v-model="form_shipping_type_id">
                                            <v-card class="mx-auto"
                                                    outlined>
                                                <v-list-item-group top>
                                                    <div v-for="(shipping, index) in shipping_types_to_display"
                                                         :key="shipping.id"
                                                         :value="shipping.id">
                                                        <label v-show="(isShowAllProvider || !form_shipping_type_id || form_shipping_type_id == shipping.id)"
                                                               @click="hideOtherProvider()">
                                                            <v-list-item>
                                                                <v-list-item-action>
                                                                    <v-radio :disabled="!canEditShippingType"
                                                                             color="primary"
                                                                             :value="shipping.id"></v-radio>
                                                                </v-list-item-action>
                                                                <v-list-item-content>
                                                                    <v-list-item-title>
                                                                        <div class="just_flex flex-wrap">
                                                                            <span>{{ shipping.name }}</span>
                                                                        </div>
                                                                    </v-list-item-title>
                                                                    <v-list-item-subtitle v-if="shipping.description && shop.web_id != '1016537'">
                                                                        {{ shipping.description }}
                                                                    </v-list-item-subtitle>

                                                                    <v-list-item-subtitle v-if="shipping.delivery_area && !(orderData.receiver_id && shippingAddresses[orderData.receiver_id])">
                                                                        <div class="text-caption">{{ $t(shipping.delivery_area) }}</div>
                                                                    </v-list-item-subtitle>
                                                                    <v-list-item-subtitle v-else-if="shipping.area_countries && (!(orderData.receiver_id && shippingAddresses[orderData.receiver_id]) || shop.web_id == '1016537')">
                                                                        <div class="text-caption">
                                                                            {{ areaCountryText(shipping.area_countries) }}
                                                                        </div>
                                                                    </v-list-item-subtitle>
                                                                    <template v-if="!isPickupType">
                                                                        <v-list-item-subtitle v-if="shipping.cod_flag || isRemoteAreaActive(shipping)">
                                                                            <v-chip v-if="isRemoteAreaActive(shipping)"
                                                                                    color="teal"
                                                                                    small
                                                                                    class="mt-1 mr-2 font-weight-bold white--text"
                                                                                    light
                                                                                    label>{{ $t('พื้นที่ห่างไกล') }}
                                                                            </v-chip>
                                                                            <v-chip v-if="shipping.cod_flag"
                                                                                    color="pink"
                                                                                    small
                                                                                    class="mt-1 mr-2 font-weight-bold white--text"
                                                                                    light
                                                                                    label>{{ $t('ชำระเงินปลายทาง') }} (COD)
                                                                            </v-chip>
                                                                        </v-list-item-subtitle>
                                                                        <div v-if="shipping.providers.length"
                                                                             class="shippingProviderList">
                                                                            <v-tooltip top
                                                                                       :key="provider"
                                                                                       v-for="provider in shipping.providers">
                                                                                <template v-slot:activator="{ on, attrs }">
                                                                                    <div v-bind="attrs"
                                                                                         v-on="on"
                                                                                         class="provider"
                                                                                         :class="(shipping.providers.length >= 2)?'small':''"
                                                                                         :data-provider="provider">
                                                                                        <img :src="require('@/assets/shipping/' + provider + '.png')"
                                                                                             alt="">
                                                                                    </div>
                                                                                </template>
                                                                                <span style="text-transform: capitalize">{{ provider }}</span>
                                                                            </v-tooltip>
                                                                            <!--suppress HtmlUnknownAttribute -->
                                                                            <div v-if="false && shipping.providers.length === 0"
                                                                                 class="provider"
                                                                                 data-provider="custom"></div>
                                                                        </div>
                                                                    </template>
                                                                    <v-list-item-subtitle v-if="false && shipping.pickup_type">
                                                                        <v-chip
                                                                            color="primary"
                                                                            small
                                                                            class="font-weight-bold white--text"
                                                                            light
                                                                            label>{{ $t('รับสินค้าด้วยตนเอง') }}
                                                                        </v-chip>
                                                                    </v-list-item-subtitle>
                                                                </v-list-item-content>
                                                                <v-list-item-action class="flex_end costColumn">
                                                                    <div style="line-height: 1.5">
                                                                        <template v-if="shipping.format == 'custom'">
                                                                            {{ $t('คิดค่าส่งภายหลัง') }}
                                                                        </template>
                                                                        <template v-else-if="(isShippingTypeForceShippingPrice(shipping.id) && orderData.force_shipping_price <= 0 ) || shipping.price <= 0">
                                                                            {{ $t('ฟรี') }}
                                                                        </template>
                                                                        <template v-else>
                                                                            <span class="baht">฿</span>
                                                                            <template v-if="isShippingTypeForceShippingPrice(shipping.id)">
                                                                                {{ number_format(orderData.force_shipping_price, 2) }}
                                                                            </template>
                                                                            <template v-else>{{ number_format(shipping.price, 2) }}</template>
                                                                        </template>
                                                                    </div>
                                                                    <div  class="minor">
                                                                        <v-tooltip bottom>
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <div v-bind="attrs"
                                                                                     v-on="on">
                                                                                    <template v-if="isPickupType">
                                                                                        {{ prepareDay + shipping.additional_prepare_day }} {{ weekendFlag ? $t('วันทำการ') : $t('วัน') }}
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        {{ shipping.period }}
                                                                                    </template>
                                                                                </div>
                                                                            </template>
                                                                            <span>
                                                                                <template v-if="isPickupType">
                                                                                    {{ $t('ระยะเวลาแพ็คสินค้า') }}
                                                                                </template>
                                                                                <template v-else>
                                                                                    {{ $t('ระยะเวลาขนส่ง') }}
                                                                                </template>
                                                                            </span>
                                                                        </v-tooltip>

                                                                    </div>
                                                                </v-list-item-action>
                                                            </v-list-item>
                                                        </label>
                                                        <v-divider v-show="(isShowAllProvider && index !== shipping_types_to_display.length-1) || (!isShowAllProvider && form_shipping_type_id === shipping.id)"></v-divider>
                                                    </div>
                                                    <div v-if="!isShowAllProvider && shipping_types_to_display.length > 1">
                                                        <label>
                                                            <v-list-item @click="showAllProvider()">
                                                                <v-list-item-action>
                                                                    <v-icon>mdi-chevron-down</v-icon>
                                                                </v-list-item-action>
                                                                <v-list-item-content>
                                                                    <v-list-item-title style="margin-bottom: 0">
                                                                        <span style="font-weight: normal">{{ $t('แสดงวิธีการจัดส่งทั้งหมด') }}</span>
                                                                    </v-list-item-title>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </label>
                                                    </div>
                                                    <div v-if="shipping_types_to_display.length == 0 && filterArea">
                                                        <label>
                                                            <v-list-item>
                                                                <v-list-item-action>
                                                                    <v-icon class="red--text">mdi-close</v-icon>
                                                                </v-list-item-action>
                                                                <v-list-item-content>
                                                                    <v-list-item-subtitle style="margin-bottom: 0">
                                                                        <span style="font-weight: normal">{{ $t('ไม่มีการวิธีการจัดส่งที่คุณค้นหา') }}</span>
                                                                    </v-list-item-subtitle>
                                                                </v-list-item-content>
                                                                <v-list-item-action>
                                                                    <a @click="filterArea = ''">ยกเลิกการค้นหา</a>
                                                                </v-list-item-action>
                                                            </v-list-item>
                                                        </label>
                                                    </div>
                                                    <div v-else-if="shipping_types_to_display.length == 0">
                                                        <label>
                                                            <v-list-item>
                                                                <v-list-item-action>
                                                                    <v-icon class="red--text">mdi-close</v-icon>
                                                                </v-list-item-action>
                                                                <v-list-item-content>
                                                                    <v-list-item-title style="margin-bottom: 0">
                                                                        <span style="font-weight: normal">{{ $t('ขออภัยค่ะ ไม่มีวิธีจัดส่งที่รองรับ') }} {{ $t('กรุณาติดต่อร้านค้า') }}</span>
                                                                    </v-list-item-title>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </label>
                                                    </div>
                                                </v-list-item-group>
                                            </v-card>
                                        </v-radio-group>
                                        </template>
                                        <div class="submitArea"
                                             :class="!isShippingTypeNextButtonDisabled?'ready':''">
                                            <v-btn class="backButton"
                                                   height="52"
                                                   text
                                                   @click="shippingStep = 1">
                                                <div class="icon-chevron-left"></div>
                                                {{ $t('กลับไปแก้ไขที่อยู่') }}
                                            </v-btn>
                                            <div @click="validate">
                                                <v-btn x-large
                                                       block
                                                       depressed
                                                       @click="(pinRequired)?shippingStep = 3:gotoNextStep()"
                                                       :disabled="isShippingTypeNextButtonDisabled"
                                                       color="primary">
                                                    {{ $t('ถัดไป') }}
                                                </v-btn>
                                            </div>
                                        </div>
                                    </template>
                                </v-stepper-content>
                            </template>
                            <template v-if="pinRequired">
                                <div id="addressStep3"></div>
                                <v-stepper-step :complete="shippingStep > 3"
                                                step="3">{{ $t('ปักหมุดแผนที่') }}
                                </v-stepper-step>
                                <v-stepper-content step="3">
                                    <v-card color="grey lighten-1"
                                            class="mb-12"
                                            height="200px"></v-card>
                                    <div class="submitArea"
                                         :class="!form_shipping_address_id?'ready':''">
                                        <v-btn class="backButton"
                                               height="52"
                                               text
                                               @click="shippingStep = 2">
                                            <div class="icon-chevron-left"></div>
                                            {{ $t('กลับไปแก้ไขวิธีจัดส่ง') }}
                                        </v-btn>
                                        <div @click="validate">
                                            <v-btn x-large
                                                   block
                                                   depressed
                                                   @click="gotoNextStep()"
                                                   :disabled="!form_shipping_address_id"
                                                   color="primary">
                                                {{ $t('ถัดไป') }}
                                            </v-btn>
                                        </div>
                                    </div>
                                </v-stepper-content>
                            </template>
                        </v-stepper>
                    </template>
                </div>
            </v-form>
        </v-scroll-y-transition>
        <!-- Step ที่อยู่ ขณะหุบอยู่ -->
        <template v-if="!isExpanded">
            <!-- กรณีไม่มีการจัดส่ง -->
            <template v-if="isOrderNoShipping">
                <v-card class="mx-auto"
                        elevation="24">
                    <v-list top>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ $t('ไม่มีการจัดส่ง') }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    {{ $t('รายการสั่งซื้อนี้ถูกกำหนดให้ไม่มีการจัดส่งผ่านผู้ให้บริการขนส่ง') }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>
            </template>
            <!-- กรณีผ่านการจัดส่งแล้ว หรือ ยังไม่ผ่านแต่สามารถแก้ไขได้อยู่ (เช่นกรณี skip การกรอกที่อยู่มา) -->
            <template v-else-if="isPass || (orderData.order_status !== 'wait_order' && canEditShippingMethod)">
                <debug-indicator file-name="src/components/order/ShippingMethod.vue"
                                 detail="v-if=!isExpanded && isPass"/>
                <v-card class="mx-auto"
                        elevation="24">
                    <!-- กรณีปกติไม่ใช่ POS -->
                    <v-list v-if="!isPOS"
                            top>
                        <v-list-item v-if="orderData.order_payment"
                                     class="pb-0">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <div class="minor mb-2">
                                        <template v-if="orderData.send_time">
                                            <span>{{ isOrderPickupType?$t('พร้อมรับเมื่อ'):$t('จัดส่งเมื่อ') }} {{ clock.diff_now_text(orderData.send_time) }}</span> ·
                                            <span class="d-inline-block minor">{{ clock.show_datetime(orderData.send_time) }}</span>
                                        </template>
                                        <template v-else>
                                            <span>{{ $t('ใช้เวลาเตรียมจัดส่งแล้ว') }} {{ clock.diff_text(clock.now_datetime(), orderData.acceptpayment_time, 2) }}</span>
                                        </template>
                                    </div>
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <div class="minor text-right mb-2 fullOpacity chip_container">
                                    <v-chip v-if="orderData.send_time"
                                            small
                                            color="light-green lighten-5"
                                            text-color="success">
                                        <v-icon x-small
                                                class="mr-1">
                                            mdi-check-circle
                                        </v-icon>
                                        {{ isOrderPickupType?$t('พร้อมรับแล้ว'):$t('จัดส่งแล้ว') }}
                                    </v-chip>
                                    <v-chip v-else
                                            small
                                            color="orange lighten-5"
                                            text-color="warning">
                                        <v-icon x-small
                                                class="mr-1">
                                            mdi-clock-time-nine-outline
                                        </v-icon>
                                        {{ isOrderPickupType?$t('รอแพ็คสินค้า'):$t('รอจัดส่ง') }}
                                    </v-chip>
                                </div>
                            </v-list-item-action>
                        </v-list-item>
                        <template v-if="orderData.trackcodes?.length">
                            <template v-if="! (orderData.trackcodes.length === 1 && orderData.trackcodes[0].shipping_provider === 'other' && orderData.trackcodes[0].code == null && orderData.trackcodes[0].description == null)">
                                <div class="trackCodeContainer"
                                     :key="provider.id"
                                     v-for="(provider,index) in orderData.trackcodes">
                                    <v-list-item class="">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <template v-if="provider.code">
                                                    <div class="d-inline-block trackcode">{{ provider.code }}</div>
                                                    <handle-copy-component class="d-inline-block ml-2"
                                                                           ref="handleCopy">
                                                        <v-btn color="primary"
                                                               text
                                                               small
                                                               v-clipboard:copy="provider.code">
                                                            <v-icon small>mdi-content-copy</v-icon>
                                                            <span class="ml-2 mobileHide"> COPY</span>
                                                        </v-btn>
                                                    </handle-copy-component>
                                                </template>
                                                <template v-else>
                                                    {{ $t('ไม่มีรหัสพัสดุ') }}
                                                </template>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                <template v-if="orderData.trackcodes.length > 1">
                                                    {{ $t('พัสดุ') }} #{{ index + 1 }} :
                                                </template>
                                                <template v-if="provider.description">
                                                    {{ provider.description }}
                                                </template>
                                                <template v-else>
                                                    <v-icon x-small>mdi-arrow-up</v-icon>
                                                    {{ $t('รหัสพัสดุของคุณ') }}
                                                </template>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-icon class="my-0">
                                            <template v-if="provider.shipping_provider && provider.shipping_provider !== 'other'">
                                                <div class="shippingProviderList">
                                                    <img :src="require('@/assets/shipping/' + provider.shipping_provider + '.png')"
                                                         alt="">
                                                </div>
                                            </template>
                                            <template v-else>
                                                <div class="shippingProviderList">
                                                    <div class="provider">
                                                        <img :src="require('@/assets/shipping/customshipping.png')"
                                                             alt="custom shipping">
                                                    </div>
                                                </div>
                                            </template>
                                        </v-list-item-icon>
                                    </v-list-item>
                                    <v-btn v-if="provider.tracking_url"
                                           class="mt-3"
                                           :outlined="orderData.order_status == 'wait_confirm' || orderData.order_status == 'completed'"
                                           large
                                           target="_blank"
                                           :href="(provider.tracking_url != 'permission_denied')?provider.tracking_url:''"
                                           @click="(provider.tracking_url == 'permission_denied')?isLoginRequired = true:''"
                                           color="primary"
                                           :block="!isDesktop">
                                        {{ $t('ติดตามรหัสพัสดุนี้') }}
                                        <v-icon class="ml-2"
                                                small>mdi-arrow-top-right
                                        </v-icon>
                                    </v-btn>
                                    <!--                                        <a target="_blank" :href="provider.tracking_url" class="black&#45;&#45;text minor d-block center mt-2">{{provider.tracking_url}}</a>-->
                                </div>
                                <v-divider class="mt-6 mb-4"></v-divider>
                            </template>
                            <div v-else-if="!isOrderPickupType">
                                <div class="trackCodeContainer">
                                    <v-list-item class="">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ $t('ไม่มีรหัสพัสดุ') }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                {{ $t('ร้านค้าไม่ได้ใส่ข้อมูลรหัสพัสดุ') }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-icon class="my-0">
                                            <div class="shippingProviderList">
                                                <img :src="require('@/assets/shipping/customshipping.png')"
                                                     alt="custom shipping">
                                            </div>
                                        </v-list-item-icon>
                                    </v-list-item>
                                </div>
                            </div>
                        </template>
                        <v-list-item class="mt-1">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <!-- กรณีเป็นวิธีจัดส่งแยกพิเศษ   -->
                                    <template v-if="isOrderNoShippingType">
                                        {{ $t('จัดส่งแยกพิเศษ') }}
                                    </template>
                                    <!-- กรณีเลือกวิธีจัดส่งปกติ   -->
                                    <template v-else>
                                        {{ selected_shipping.name }}
                                    </template>
                                </v-list-item-title>
                                <v-list-item-subtitle v-if="!orderData.send_time"
                                                      class="mt-1">
                                    {{ $t('ระยะเวลาแพ็คสินค้า') }} {{ prepareDay }} {{ weekendFlag ? $t('วันทำการ') : $t('วัน') }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-if="!orderData.send_time && weekendFlag"
                                                      class="mt-1"
                                                      style="font-size: 0.8em;">
                                    · {{ $t('หยุดวันเสาร์-อาทิตย์ และวันหยุดนักขัตฤกษ์') }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-if="!orderData.send_time && showHolidayDate"
                                                      class="mb-1"
                                                      style="font-size: 0.8em;">
                                    · {{ $t('หยุดเพิ่มเติม') }} : {{ $d(new Date(holidayDate[0].replace(/-/g, "/")), 'short') }} - {{ $d(new Date(holidayDate[1].replace(/-/g, "/")), 'short') }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle class="mt-1"
                                                      v-if="selected_shipping.period && !selected_shipping.pickup_type">{{ $t('ระยะเวลาขนส่ง') }} {{ selected_shipping.period }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-else class="mt-1">
                                    <template v-if="orderData.num != orderData.quantity">{{ orderData.num }} {{ $t('รายการ') }} {{ $t('รวม') }}</template>
                                    <template v-else>{{ $t('สินค้า') }}</template>
                                    {{ orderData.quantity }} {{ $t('ชิ้น') }} · {{ $t('น้ำหนัก') }} {{ orderData.weight / 1000 }} {{ $t('กก.') }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-if="selected_shipping.cod_flag">
                                    <v-chip color="pink"
                                            small
                                            class="mt-2 font-weight-bold white--text"
                                            light
                                            label>{{ $t('ชำระเงินปลายทาง') }} (COD)
                                    </v-chip>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-if="selected_shipping.pickup_type">
                                    <v-chip color="primary"
                                            small
                                            class="mt-2 font-weight-bold white--text"
                                            light
                                            label>{{ $t('รับสินค้าด้วยตนเอง') }}
                                    </v-chip>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-if="orderData.trackcodes">
                                    <debug-indicator file-name="ShippingMethod.vue"
                                                     detail="JSON.stringify(orderData.trackcodes, false, 2)"></debug-indicator>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <div class="shippingProviderList">
                                    <template v-if="selected_shipping.providers">
                                        <v-tooltip top
                                                   :key="provider"
                                                   v-for="provider in selected_shipping.providers">
                                            <template v-slot:activator="{ on, attrs }">
                                                <div v-bind="attrs"
                                                     v-on="on"
                                                     class="provider"
                                                     :class="(selected_shipping.providers.length >= 2)?'small':''"
                                                     :data-provider="provider">
                                                    <img :src="require('@/assets/shipping/' + provider + '.png')"
                                                         alt="">
                                                </div>
                                            </template>
                                            <span style="text-transform: capitalize">{{ provider }}</span>
                                        </v-tooltip>
                                        <div v-if="selected_shipping.providers.length === 0"
                                             class="provider"
                                             data-provider="custom"></div>
                                    </template>
                                    <div v-else-if="isOrderNoShippingType"
                                         class="shippingProviderList">
                                        <div class="provider"
                                             data-provider="custom"></div>
                                    </div>
                                </div>
                            </v-list-item-action>
                            <v-list-item-action v-if="false">
                                <div class="">
                                    <span class="baht">฿</span>
                                    <template v-if="isShippingTypeForceShippingPrice(selected_shipping.id)">{{ number_format(orderData.force_shipping_price, 2) }}</template>
                                    <template v-else>{{ number_format(selected_shipping.price, 2) }}</template>
                                </div>
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item class="mb-1">
                            <v-list-item-content>
                                <v-list-item-title v-if="isOrderPickupType">
                                    <div class="grey--text text-caption mb-2">{{ $t('ที่อยู่สำหรับกรณีที่ไม่เข้ามารับสินค้าตามที่กำหนด') }}</div>
                                </v-list-item-title>

                                <!-- กรณีมีข้อมูลที่อยู่ผู้รับเรียบร้อยแล้ว-->
                                <template v-if="isReceiverInfoInFormReady">
                                    <template v-if="isDropshipActive && orderData.dealer">
                                        <div class="deep-orange--text text-caption mb-2">{{ $t('ที่อยู่ผู้ส่งพัสดุ') }}</div>
                                        <v-list-item-title>
                                            {{ orderData.dealer.name }}
                                            <template v-if="orderData.dealer.telephone">
                                                <span class="minor"> · </span>
                                                <div class="d-inline-block">{{ orderData.dealer.telephone }}</div>
                                            </template>
                                            <template v-else-if="orderData.dealer.mobile">
                                                <span class="minor"> · </span>
                                                <div class="d-inline-block">{{ mobile_format(orderData.dealer.mobile) }}</div>
                                            </template>
                                        </v-list-item-title>
                                        <v-list-item-subtitle class="mt-1 mb-4">
                                            <template v-if="orderData.dealer.use_shop_address">
                                                <span class="disabled">[ Auto - {{ $t('ที่อยู่ของร้าน') }} ]</span>
                                            </template>
                                            <template v-else>
                                                {{ address_format(orderData.dealer) }}
                                            </template>
                                        </v-list-item-subtitle>
                                    </template>
                                    <template v-if="isDropshipActive">
                                        <div class="deep-orange--text text-caption mb-2">{{ $t('ที่อยู่ผู้รับพัสดุ') }}</div>
                                    </template>
                                    <template v-if="isSkippedReceiver && !isPassReceiverContact">
                                        <v-list-item-title>
                                            {{ $t('ยังไม่มีที่อยู่ผู้รับ') }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle class="mt-1">
                                            {{ $t('เนื่องจากมีการกำหนดให้กรอกที่อยู่ภายหลังการยืนยัน') }}
                                        </v-list-item-subtitle>
                                    </template>
                                    <template v-else>
                                        <v-list-item-title>
                                            {{ orderData.receiver.name }}
                                            <template v-if="orderData.receiver.telephone">
                                                <span class="minor"> · </span>
                                                <div class="d-inline-block">{{ orderData.receiver.telephone }}</div>
                                            </template>
                                            <template v-else-if="orderData.receiver.mobile">
                                                <span class="minor"> · </span>
                                                <div class="d-inline-block">{{ mobile_format(orderData.receiver.mobile) }}</div>
                                            </template>
                                        </v-list-item-title>
                                        <v-list-item-subtitle class="mt-1">
                                            {{ address_format(orderData.receiver) }}
                                        </v-list-item-subtitle>
                                    </template>
                                </template>
                                <!-- กรณีมีข้อมูลยังไม่ได้กรอกที่อยู่ผู้รับ (และยังแก้ไขได้อยู่) -->
                                <template v-else>
                                    <template v-if="orderData.order_status == 'wait_order'">
                                        <v-list-item-title>
                                            {{ $t('ยังไม่มีที่อยู่ผู้รับ') }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle class="mt-1">
                                            {{ $t('เนื่องจากมีการกำหนดให้กรอกที่อยู่ภายหลังการยืนยัน') }}
                                        </v-list-item-subtitle>
                                    </template>
                                    <template v-else>
                                        <template v-if="isOrderPickupType">
                                            <v-alert class="mb-0"
                                                     color="#ccc"
                                                     type="info"
                                                     text>
                                                <v-row align="center">
                                                    <v-col class="grow p-0">
                                                        {{ $t('กรุณากรอกที่อยู่ผู้รับ') }}
                                                    </v-col>
                                                    <v-col class="shrink p-0">
                                                        <v-btn @click="actionExpandStep({stepName: 'shippingMethod', close_another: true});"
                                                               color="primary">{{ $t('กรอกทันที') }}
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-alert>
                                        </template>
                                        <v-alert v-else
                                                 class="mb-0"
                                                 color="primary"
                                                 type="info"
                                                 text>
                                            <v-row align="center">
                                                <v-col class="grow p-0">
                                                    {{ $t('กรุณากรอกที่อยู่ผู้รับ') }}
                                                </v-col>
                                                <v-col class="shrink p-0">
                                                    <v-btn @click="actionExpandStep({stepName: 'shippingMethod', close_another: true});"
                                                           color="primary">{{ $t('กรอกทันที') }}
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-alert>
                                    </template>
                                </template>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <!-- กรณี POS -->
                    <v-list v-else>
                        <v-list-item class="pb-0">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <div class="minor mb-2">
                                        <template>
                                            <span>{{ isOrderPickupType?$t('พร้อมรับเมื่อ'):$t('จัดส่งเมื่อ') }} {{ clock.diff_now_text(orderData.order_time) }}</span> ·
                                            <span class="d-inline-block minor">{{ clock.show_datetime(orderData.order_time) }}</span>
                                        </template>
                                    </div>
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <div class="minor text-right mb-2 fullOpacity chip_container">
                                    <v-chip small
                                            color="light-green lighten-5"
                                            text-color="success">
                                        <v-icon x-small
                                                class="mr-1">
                                            mdi-check-circle
                                        </v-icon>
                                        {{ isOrderPickupType?$t('พร้อมรับแล้ว'):$t('จัดส่งแล้ว') }}
                                    </v-chip>
                                </div>
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ $t('รับสินค้าหน้าร้าน') }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    <template v-if="orderData.num != orderData.quantity">{{ orderData.num }} {{ $t('รายการ') }} {{ $t('รวม') }}</template>
                                    <template v-else>{{ $t('สินค้า') }}</template>
                                    {{ orderData.quantity }} {{ $t('ชิ้น') }} · {{ $t('น้ำหนัก') }} {{ orderData.weight / 1000 }} {{ $t('กก.') }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <div class="">
                                    <span class="baht">฿</span>{{ number_format(selected_shipping.price, 2) }}
                                </div>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-card>
            </template>
            <!-- กรณียังไม่ผ่านการจัดส่ง แต่เลยสถานะยืนยันมาแล้ว -->
            <template v-else-if="orderData.order_status !== 'wait_order'">
                <v-card class="mx-auto"
                        elevation="24">
                    <v-list top>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ $t('ไม่มีข้อมูลผู้รับ') }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    {{ $t('รายการสั่งซื้อนี้ร้านค้าเป็นผู้สร้างผ่านระบบจัดการให้ลูกค้าที่สั่งซื้อ') }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>
            </template>
            <!-- ปกติแล้วจะไม่มี v-else สุดท้ายของกล่องที่หุบอยู่ ถ้ายังไม่ผ่าน (ให้เหลือแต่หัวข้อ) -->
        </template>
    </div>
</template>
<style scoped></style>
<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import Helper from "../../libraries/Helper";
import DebugIndicator from "@/components/DebugTools/DebugIndicator";
import {clock} from "@/libraries/DateTime.js";
import ThailandAddress from "@/components/Address/ThailandAddress.vue";
import SenderAddress from "@/components/Order/components/ShippingMethod/SenderAddress.vue";
import InternationalAddress from "@/components/Address/InternationalAddress.vue";
import HandleCopyComponent from "@/components/Order/components/HandleCopyComponent.vue";
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";
import store from "@/store";
import {SET_IS_LOGIN_REQUIRED} from "@/store/modules/order/mutation-types";
import country_db from "../../../resources/country-code.json";

// const zeroFill = (digit) => digit<10 ? '0'+digit : digit.toString();
const mobileRegexTH = /^(0[689]\d{8}|\+66[689]\d{8})$/;
const mobileRegexEN = /^\+?\d{4,14}$/;

export default {
    name: "ShippingMethod",
    components: {
        DebugIndicator,
        ThailandAddress,
        SenderAddress,
        InternationalAddress,
        HandleCopyComponent
    },
    mixins: [MixinsHelper],
    data(){
        return {
            thailandAddressData: {
                postalCode: null,
                selectedPostalList: 'custom',
                custom_province: null,
                custom_district: null,
                custom_subdistrict: null,
            },
            internationalAddressData: {},
            shippingStep: 1,
            forceShippingStep1: false,
            forceShippingStep2: false,
            name: null,
            mobileNumber: null,
            extNumber: null,
            address: null,
            splittingAddress: false,
            canSplitAddress: true,
            is_dummy_receiver_contact: false, // ในกรณีที่ user ไม่ได้เป็นเจ้าของ receiver contact ... dummy จะเป็น true
            commonRules: [
                v => !!v || this.$t('จำเป็นต้องระบุ'),
            ],
            postalRules: [
                v => !!v || this.$t('จำเป็นต้องระบุ'),
                v => (v && (v.length === 5)) || this.$t('รหัสไปรษณีย์ไม่ถูกต้อง')
            ],
            postalListSearchSting: '', // ค้นหา ตำบล อำเภอ จังหวัด
            temporary_data: null,
            isShowAllAddress: true,
            isShowAllProvider: true,
            isClickedDisabledButton: false,
            search_textbox: '',
            isSearching: false,
            isShipOversea: false,
            isPickupType: 0,
            filterArea: '',
        };
    },
    computed: {
        ...mapGetters({
            isExpanded: 'order/isExpandShippingMethod',
            isPassStepBuyerInfo: 'order/isPassStepBuyerInfo',
            isPassStepPaymentMethod: 'order/isPassStepPaymentMethod',
            isPass: 'order/isPassStepShippingMethod',
            gointer_enabled: 'order/gointer',
            shipping_types: 'order/sortedShippingTypes',
            isLogin: 'user/isLogin',
            isDropshipActive: 'order/isDropshipActive',
            isOrderPickupType: 'order/isOrderPickupType',
            shippingReceiverAddresses: 'user/use_this_receiverAddresses',
            shippingDealerReceiverAddresses: 'user/use_this_dealerReceiverAddresses',
            sortedReceiverAddresses: 'user/use_this_sortedReceiverAddresses',
            sortedDealerReceiverAddresses: 'user/use_this_sortedDealerReceiverAddresses',
            canEditShippingMethod: 'order/canEditShippingMethod',
            canEditShippingType: 'order/canEditShippingType',
            canEditReceiverContact: 'order/canEditReceiverContact',
            selected_shipping: 'order/selectedShipping',
            isShippingIsLastStep: 'order/isShippingIsLastStep',
            isRequiredReceiver: 'order/isRequiredReceiver',
            isPassReceiverContact: 'order/isPassReceiverContact',
            isOrderNoShipping: 'order/isOrderNoShipping',
            isOrderNoShippingType: 'order/isOrderNoShippingType',
            shop: 'order/shop',
        }),
        ...mapState({
            orderData: state => state.order.orderData,
            fetchedReceiverAddresses: state => state.user.paginated_metadata.receiverAddresses.firstFetched,
            allMoreThanPageLimit: state => state.user.paginated_metadata.receiverAddresses.allMoreThanPageLimit,
            hasMore: state => state.user.paginated_metadata.receiverAddresses.more,
            allMoreThanPageLimit_DEALER: state => state.user.paginated_metadata.dealerReceiverAddresses.allMoreThanPageLimit,
            hasMore_DEALER: state => state.user.paginated_metadata.dealerReceiverAddresses.more,
            prepareDay: state => state.order.orderData.prepare_duration, // ระยะเวลาเตรียมสินค้า
            weekendFlag: state => state.order.orderData.prepare_weekday, // หยุดเสาร์อาทิตย์
            holidayDate: state => state.order.shopData.holiday_date, // วันหยุด (ถ้าเป็น lnwshop จะเป็น array เริ่มต้น, สิ้นสุด
        }),
        internationalRules() {
            return [
                v => !!v || this.$t('จำเป็นต้องระบุ'),
                v => (/^[A-Za-z0-9\s,._/-]+$/).test(v) || this.$t('กรุณากรอกเฉพาะตัวอักษร A-Z, a-z, ตัวเลข และอักขระที่อนุญาต (, . _ - /)')
            ];
        },
        prepareDayWithAdditional() {
            if(this.isPickupType){
                const max_day = Math.max(...this.shipping_types_to_display.map(item => item.additional_prepare_day)) + this.prepareDay;
                const min_day = Math.min(...this.shipping_types_to_display.map(item => item.additional_prepare_day)) + this.prepareDay;
                if(min_day != 'Infinity' && max_day != 'Infinity'){
                    if(min_day == max_day){
                        return min_day;
                    }
                    else {
                        return min_day + ' - ' + max_day;
                    }
                }
                return '-';
            }
            return this.prepareDay;
        },
        itemsSelectShippingOversea(){
            return [
                {text: this.$t('จัดส่งในประเทศไทย'), value: false},
                {text: this.$t('จัดส่งต่างประเทศ'), value: true}
            ];
        },
        itemsSelectShippingAddressForDealer(){
            return [
                {text: this.$t('ที่อยู่ของฉัน'), value: false},
                {text: this.$t('ที่อยู่ของลูกค้าของฉัน'), value: true}
            ];
        },
        use_this_type(){
            let _type = 'receiverAddresses';
            if(this.isDropshipActive && this.form_shipping_address_is_dealer){
                _type = 'dealerReceiverAddresses'
            }
            return _type;
        },
        use_this_allMoreThanPageLimit(){
            if(this.use_this_type == 'dealerReceiverAddresses'){
                return this.allMoreThanPageLimit_DEALER;
            }
            return this.allMoreThanPageLimit;
        },
        use_this_hasMore(){
            if(this.use_this_type == 'dealerReceiverAddresses'){
                return this.hasMore_DEALER;
            }
            return this.hasMore;
        },
        shippingAddresses(){
            if(this.isDropshipActive){
                return {...this.shippingDealerReceiverAddresses, ...this.shippingReceiverAddresses};
            }
            return this.shippingReceiverAddresses;
        },
        sortedForAllAddresses(){
            if(this.isDropshipActive && this.form_shipping_address_is_dealer){
                return this.sortedDealerReceiverAddresses;
            }
            return this.sortedReceiverAddresses;
        },
        isLoginRequired: {
            get(){
                return this.$store.state.order.isLoginRequired;
            },
            set(value){
                this.$store.commit('order/' + SET_IS_LOGIN_REQUIRED, value);
            },
        },
        addressRules(){
            const rules = []
            if(this.isRequiredReceiver){
                const rule = v => !!v || this.$t('กรุณาเลือกที่อยู่จัดส่งด้วยนะคะ');
                rules.push(rule);
            }
            return rules
        },
        // https://th.wikipedia.org/wiki/%E0%B8%AB%E0%B8%A1%E0%B8%B2%E0%B8%A2%E0%B9%80%E0%B8%A5%E0%B8%82%E0%B9%82%E0%B8%97%E0%B8%A3%E0%B8%A8%E0%B8%B1%E0%B8%9E%E0%B8%97%E0%B9%8C%E0%B9%83%E0%B8%99%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B9%80%E0%B8%97%E0%B8%A8%E0%B9%84%E0%B8%97%E0%B8%A2
        mobileNumberRules(){
            return [
                v => !!v || this.$t('จำเป็นต้องระบุ'),
                v => !!/^0[689][0-9]{8}$/.test(v) || this.$t('รูปแบบไม่ถูกต้อง'),
            ];
        },
        anyPhoneNumberRules(){
            return [
                v => !!v || this.$t('จำเป็นต้องระบุ'),
                v => !!/^((0[689][0-9]{8})|(0[23457][0-9]{7}))$/.test(v) || this.$t('รูปแบบไม่ถูกต้อง'),
            ];
        },
        telephoneRules(){
            return [
                v => !!v || this.$t('จำเป็นต้องระบุ'),
                v => (!v
                    || this.validateMobileNumber(v))
                    || this.$t('รูปแบบไม่ถูกต้อง'),
                ];
        },
        editAddressId: {
            set(value){
                this.$store.commit('order/setForm', {editAddressId: value});
            },
            get(){
                return this.$store.state.order.form.editAddressId;
            },
        },


        // กำลังเลือกอันไหน  0=ยังไม่เลือก  -1=สร้างใหม่
        form_shipping_address_id: {
            set(value){
                this.$store.commit('order/setForm', {key: 'shippingMethodShippingAddressId', value: value});
            },
            get(){
                return this.$store.state.order.form.shippingMethodShippingAddressId;
            },
        },
        // กำลังเลือกอันไหน  0 = receiver (ที่อยู่ของฉัน)  1 = dealer_receiver (ที่อยู่ของลูกค้าของฉัน)
        form_shipping_address_is_dealer: {
            set(value){
                this.$store.commit('order/setForm', {key: 'shippingMethodShippingDealerAddress', value: value});
            },
            get(){
                return this.$store.state.order.form.shippingMethodShippingDealerAddress;
            },
        },
        form_shipping_type_id: {
            set(value){
                this.$store.commit('order/setForm', {key: 'shipping_type_id', value: value});
            },
            get(){
                return this.$store.state.order.form.shipping_type_id;
            },
        },

        isForceShippingPrice(){
            return this.orderData.force_shipping_price != null;
        },

        pinRequired(){
            /* @todo ถ้าเลือกวิธีจัดส่งที่เป็น messenger หรือ มีระบบให้ร้านตั้งค่าได้แล้วว่าจะให้ลูกค้าปักหมุดไหม */
            return false; //this.form_shipping_type_id === 11

        },
        isAddressEditorFormShow(){
            return this.editAddressId !== 0;
        },
        isAddressEdit(){
            return this.editAddressId !== 0 && this.sortedForAllAddresses.length > 0;
        },
        isShippingTypeNextButtonDisabled(){
            if(
                !this.form_shipping_type_id
                || !this.canEditShippingType
                || !this.shipping_types_to_display.filter(shipping => shipping.id == this.form_shipping_type_id).length
            )
                return true;
            return false;
        },
        isReceiverInfoInFormReady(){
            let is_ready = true;
            if(
                !this.isAddressEditorFormShow // ไม่ได้แสดงฟอร์มแก้ไขอยู่
                && this.form_shipping_address_id // และ เลือกที่อยู่ ที่มีอยู่แล้ว เรียบร้อยแล้ว
            )
                return true;
            // console.log(this.$refs.receiverAddress);
            if((this.$refs.receiverAddress && !this.$refs.receiverAddress.validate())
                || (!this.address)
                || (this.$refs.receiverName && !this.$refs.receiverName.validate())
                || (this.$refs.receiverMobileNumber && !this.$refs.receiverMobileNumber.validate())
            ){
                is_ready = false;
            }
            // console.log(this.address);

            if(this.isShipOversea){
                if(!this.internationalAddressData.country
                    || !this.internationalAddressData.country_code
                    || !this.internationalAddressData.postalCode
                    || !this.internationalAddressData.custom_province){
                    is_ready = false;
                }
            }else{
                if((!this.thailandAddressData.postalCode || this.thailandAddressData.postalCode.length !== 5)
                    || this.thailandAddressData.selectedPostalList === null
                    || (this.thailandAddressData.selectedPostalList === 'custom'
                        && (!this.thailandAddressData.custom_province
                            || !this.thailandAddressData.custom_district
                            || !this.thailandAddressData.custom_subdistrict
                        ))){
                    is_ready = false;
                }
            }


            return is_ready;
        },

        canSkipReceiver(){
            if(this.isPassReceiverContact || this.isAddressEdit){
                return false; //ถ้ามีที่อยู่แล้วจะแก้เป็นข้ามไม่ได้
            }

            return !this.isRequiredReceiver;
        },
        showSkipReceiverButton(){
            if(this.orderData.order_status == 'wait_order'
                && !this.isRequiredReceiver
                && !this.isPassReceiverContact
                && this.shippingStep == 1
            )
                return true;
            return false
        },

        isSkippedReceiver(){
            //นำออกเพราะจริงๆ แค่รู้ว่า skip หรือยังก็พอ
            // if(!this.isRequiredReceiver
            //     && this.orderData.skip_receiver_flag
            //     && !this.isPassReceiverContact
            // ){
            //     return true;
            // }
            if(this.orderData.skip_receiver_flag == '1'){
                return true;
            }
            return false;
        },

        isReceiverNextButtonDisabled(){
            if(!this.canEditReceiverContact)
                return true;

            if(this.form_shipping_address_id === 0)
                return true;

            return !this.isReceiverInfoInFormReady;
        },
        clock(){
            return clock;
        },
        // sessionStorageKey(){
        //     return 'o' + this.$store.state.order.order_id + 'Ship';
        // },
        sessionRememberItems(){
            return [
                'name',
                'mobileNumber',
                'address',
                'thailandAddressData.custom_province',
                'thailandAddressData.custom_district',
                'thailandAddressData.custom_subdistrict',
                'thailandAddressData.postalCode',
                'thailandAddressData.selectedPostalList',
            ];
        },

        showHolidayDate(){
            return this.holidayDate
                && Array.isArray(this.holidayDate)
                && this.holidayDate[0]
                && this.holidayDate[1];
        },
        hasPickupType() {
            if(this.isOrderNoShippingType) {
                return false;
            }
            for(const shipping of this.shipping_types_that_not_price_false){
                if(shipping.pickup_type){
                    return true;
                }
            }
            return false;
        },
        hasPickupTypeButNoShippingType() {
            let noShippingType = true
            for(const shipping of this.shipping_types_that_not_price_false){
                if(!shipping.pickup_type){
                    noShippingType = false;
                }
            }
            return this.hasPickupType && noShippingType;
        },
        shipping_types_that_not_price_false(){
            const result = [];

            for(const shipping of this.shipping_types) {
                if(shipping.price !== false){
                    // console.log('shipping in result: ', shipping);
                    if(this.showThisShipType(shipping)){
                        // console.log('shipping checked: ', shipping);
                        result.push(shipping);
                    }
                }
            }
            // console.log('result: ', result);

            return result;
        },
        shipping_types_to_display(){
            // console.log('shipping_types_that_not_price_false:', this.shipping_types_that_not_price_false);
            let shipping_types = this.shipping_types_that_not_price_false.filter(shipping => {
                // Log the shipping object and the conditions
                // console.log('Evaluating shipping:', shipping);

                const isPickupTypeMatch = (shipping.pickup_type === this.isPickupType);
                const isAreaMatch = (
                    !this.isPickupType ||
                    this.filterArea == null ||
                    this.filterArea == '' ||
                    this.filterArea == 'ทั่วประเทศ' ||
                    (shipping.name && shipping.name.indexOf(this.filterArea) != -1) ||
                    (shipping.description && shipping.description.indexOf(this.filterArea) != -1) ||
                    (shipping.detail && shipping.detail.indexOf(this.filterArea) != -1) ||
                    (shipping.delivery_area && shipping.delivery_area.indexOf(this.filterArea) != -1)
                );

                // Log the evaluation results
                // console.log('isPickupTypeMatch:', isPickupTypeMatch);
                // console.log('isAreaMatch:', isAreaMatch);

                return isPickupTypeMatch && isAreaMatch;
            });

            // console.log('Filtered shippings:', shipping_types);
            return shipping_types;
        },
        shipping_pickup_delivery_areas() {
            const result = ['ทั่วประเทศ'];

            for(const shipping of this.shipping_types_that_not_price_false) {
                if(shipping.pickup_type  === 1){
                    if(!result.includes(shipping.delivery_area)){
                        result.push(shipping.delivery_area);
                    }
                }
            }
            return result;
        },
        isPOS(){
            return this.orderData.owner_create_flag == 3;
        },
        countryDatabase() {
            // ดราม่า ONE CHINA
            if (this.shop && ((this.shop.web_id+'') === '1016537')) {
                const db = country_db;
                db['CN'] = 'Mainland China'
                db['HK'] = 'Hong Kong SAR'
                db['MO'] = 'Macao SAR'
                db['TW'] = 'Taiwan Region'
                return db
            } else {
                return country_db;
            }
        },
        countryList() {
            return Object.values(this.countryDatabase).sort();
        },
        only_this_international_country() {
            let shipping_types = this.shipping_types.filter(shipping => {
                return !shipping.thai_type
            });
            let countries = [];
            shipping_types.forEach(shipping => {
               if(shipping.area_flag == 0){
                   // countries = [''];
                   return;
               }else{
                   countries = countries.concat(shipping.area_countries);
               }
            });
            return [...new Set(countries)];
        },
        only_this_international_country_text() {
            return this.areaCountryText(this.only_this_international_country)
        }
    },
    methods: {
        ...mapActions({
            nextStep: 'order/nextStep',
            previousStep: 'order/previousStep',
            actionExpandStep: 'order/expandStep',
            saveShippingMethod: 'order/saveShippingMethod',
            actionContactSplit: 'order/contactSplit',
            actionUpdateOrderReceiver: 'order/updateOrderReceiver',
            actionUpdateOrderSkipReceiver: 'order/updateOrderSkipReceiver',
            closeAddressEditorForm: 'order/shippingMethodCloseAddressEditorForm',
            _loadMoreAddresses: 'user/loadMoreAddresses',
            _searchAddresses: 'user/searchAddresses',
        }),
        number_format(num, decimal){
            return Helper.number_format(num, decimal);
        },
        onlyNumberInput(){
            if(!this.mobileNumber){
                return
            }
            const filtered = this.mobileNumber.replace(/[^0-9+]/g, '');
            if(filtered !== this.mobileNumber)
                this.mobileNumber = filtered;


        },
        validateMobileNumber(number){
            if(!this.isShipOversea){
                return mobileRegexTH.test(number);
            }else{
                return mobileRegexEN.test(number);
            }
        },
        mobile_format(num){
            return Helper.mobile_format(num);
        },
        address_format(address){
            // let address_text = ''; // assigned value but never used
            let sep = ' ';
            // if(this.$i18n.locale == 'en'){
            //     sep = ', '
            // }
            return address.address + sep + address.subdistrict + sep + address.district + sep + address.province + sep + address.zipcode;
        },
        // อะไรที่อยากให้ทำ ตอนที่ Component นี้ถูก mounted หรือถูก Expand ออกมา ให้เพิ่มที่ method นี้
        MY_activated(){
            if(this.forceShippingStep1){
                this.shippingStep = 1;
                this.forceShippingStep1 = false;
            }else if(this.forceShippingStep2){
                this.shippingStep = 2;
                this.forceShippingStep2 = false;
            }else{
                // กรณีที่ร้านตั้งค่า skip_receiver_flag มา หรือลูกค้าเคยกด skip มาทีแล้ว ให้ข้ามไป step 2 เลย
                if(this.orderData.order_status === 'wait_order'
                    && (this.isSkippedReceiver || this.orderData.receiver_id)){
                    this.shippingStep = 2;
                }else{
                    this.shippingStep = 1;
                }
            }

            if(this.sortedForAllAddresses.length === 0){
                this.addNewAddress();
                this.restoreDataFromSessionStorage();
            }
            // comment เพราะเวลาแก้ไข อยากให้แสดงทั้งหมดเพื่อเลือกใหม่ไปเลย
            // if (this.form_shipping_address_id) {
            //     this.isShowAllAddress = false;
            // }
            // if (this.form_shipping_type_id) {
            //     this.isShowAllProvider = false;
            // }

            if(this.orderData.receiver && this.orderData.receiver.country_code && this.orderData.receiver.country_code != '' && this.orderData.receiver.country_code != 'TH'){
                this.isShipOversea = true;
            }

            if(this.hasPickupType && this.isOrderPickupType){
                this.isPickupType = 1;
                // this.editAddressId = 0;
            }
            if(this.isExpanded){
                if(this.isPassStepBuyerInfo){
                    this.scrollTo('#addressStep1', 50);
                }else{
                    this.scrollTo('#step2', 50);
                }
            }
            // console.log('----shipping types',this.shipping_types);
        },
        MY_deactivated(){
            // console.log(this.editAddressId);
            if(this.shippingStep === 1){ // อยู่ในขั้นตอนกรอกที่อยู่
                this.saveCurrentDataInSessionStorage();
            }
        },
        showAllAddress(){
            this.isShowAllAddress = true;
            this.editAddressId = 0;
            this.abortSearchAddresses();
        },
        showAllProvider(){
            this.isShowAllProvider = true;
        },
        hideOtherAddress(){
            if(this.sortedForAllAddresses.length > 3){
                this.isShowAllAddress = false;
                this.scrollTo('#addressStep1', 50);
                setTimeout(function (){
                    store.dispatch('order/setIsShowOverlay', false);
                }, 500);
            }
        },
        hideOtherProvider(){
            if(this.shipping_types_to_display.length > 3){
                this.isShowAllProvider = false;
                this.scrollTo('#addressStep2', 50);
                setTimeout(function (){
                    store.dispatch('order/setIsShowOverlay', false);
                }, 500);
            }
        },
        showThisAddress(address){
            if(!this.gointer_enabled){
                return true;
            }
            if(this.isShipOversea){
                if(address.country_code && address.country_code != '' && address.country_code != 'TH'){
                    return true;
                }
            }else{
                if(!address.country_code || address.country_code == 'TH'){
                    return true;
                }
            }
            return false;
        },
        isRemoteAreaActive(shipping){
            if(shipping && typeof shipping.remote_area != 'undefined'
                && this.form_shipping_address_id > 0
                && shipping.remote_area.indexOf(this.shippingAddresses[this.form_shipping_address_id].zipcode) !== -1){
                return true;
            }
            return false;
        },
        showThisShipType(shipping) {

            // Check if `gointer_enabled` is off and `thai_type` is true
            if (!this.gointer_enabled && shipping.thai_type) {
                // console.log('Gointer not enabled and shipping is Thai type.');
                return true;
            }

            // Initialize receiver_id
            let receiver_id = this.orderData.receiver_id;
            if (!receiver_id) {
                if (this.orderData.receiver) {
                    receiver_id = this.orderData.receiver.contact_id;
                    // console.log('Receiver ID from receiver object:', receiver_id);
                }
            } else {
                // console.log('Receiver ID from orderData:', receiver_id);
            }

            // Determine shipping type based on `isShipOversea` and receiver country code
            if (
                this.isShipOversea ||
                (
                    receiver_id &&
                    this.shippingAddresses[receiver_id]?.country_code &&
                    this.shippingAddresses[receiver_id]?.country_code != '' &&
                    this.shippingAddresses[receiver_id]?.country_code != 'TH'
                )
            ) {
                // console.log('Overseas shipping or receiver is outside Thailand.');
                if (!shipping.thai_type) {
                    // console.log('Shipping is not Thai type. Returning true.');
                    return true;
                }
            } else {
                // console.log('Domestic shipping or receiver is in Thailand.');
                if (shipping.thai_type) {
                    // console.log('Shipping is Thai type. Returning true.');
                    return true;
                }
            }

            // console.log('No conditions matched. Returning false.');
            return false;
        },
        showCountryName(address){
            if(address === undefined){
                return null;
            }
            if(address.country){
                return address.country;
            }
            if(address.country_code){
                return this.getCountryNameByCode(address.country_code);
            }
        },
        searchAddressesEnter(event){
            if(this.search_textbox && this.search_textbox != ""){
                this.searchAddresses();
            }else{
                this.abortSearchAddresses();
            }
            if(event){
                event.target.blur();
                event.preventDefault();
            }
            return false;
        },
        searchPickupMethodEnter(){
            this.$refs.comboBox.blur();
            return false;
        },
        addNewAddress(){ // กดปุ่มเพิ่มที่อยู่ใหม่
            if(this.canEditReceiverContact)
                this.editAddress(-1);
        },
        async splitAddress(){
            if(this.editAddressId != -1
                || !this.address
                || !this.canSplitAddress
                || this.isShipOversea
                || this.splittingAddress){
                return;
            }
            this.splittingAddress = true;
            if(this.postalCode){
                return;
            }
            await store.dispatch('order/setIsAjaxLoading', true);
            this.resetValidate();
            const payload = {
                address: this.address,
            };
            this.actionContactSplit(payload)
                .then((response) => {
                    store.dispatch('order/setIsAjaxLoading', false);
                    let contact = response.data?.response?.contact;
                    if(!contact){
                        this.canSplitAddress = false;
                        this.splittingAddress = false;
                        return false;
                    }
                    if(contact.address){
                        this.address = contact.address;
                    }
                    if(contact.name && !this.name){
                        this.name = contact.name
                    }
                    if(contact.telephone && (contact.telephone != this.mobileNumber)){
                        this.mobileNumber = contact.telephone;
                    }
                    if(contact.zipcode && (contact.zipcode != this.thailandAddressData.postalCode)){
                        this.thailandAddressData.selectedPostalList = 'custom';
                        if(contact.subdistrict && !this.thailandAddressData.custom_subdistrict){
                            this.thailandAddressData.custom_subdistrict = contact.subdistrict;
                        }
                        if(contact.district && !this.thailandAddressData.custom_district){
                            this.thailandAddressData.custom_district = contact.district;
                        }
                        if(contact.province && !this.thailandAddressData.custom_province){
                            this.thailandAddressData.custom_province = contact.province;
                        }
                        if(contact.zipcode && !this.thailandAddressData.postalCode){
                            this.thailandAddressData.postalCode = contact.zipcode;
                        }
                    }
                    this.canSplitAddress = false;
                    this.splittingAddress = false;
                })
                .catch(async (errors) => {
                    await store.dispatch('order/setIsShowOverlay', false);
                    await store.dispatch('order/setIsAjaxResponse', false);
                    await store.dispatch('order/setIsAjaxResponse', true);
                    await store.dispatch('order/setIsAjaxResponseMessage', errors);
                    // console.error(errors[0]);
                })
        },
        // เปิดฟอร์มเพื่อแก้ไข receiver contact ที่มีอยู่แล้ว
        editAddress(id){
            this.editAddressId = id;
            //ถ้ามีการแก้ไขหรือเพิ่มที่อยู่ ให้ยกเลิกการเลือกไปแล้วด้วย
            if(this.form_shipping_address_id > 0){
                this.submitUnsetOrderReceiver();
            }
            if(id === -1){ // เพิ่มที่อยู่ใหม่ จะส่ง id มาเป็น -1
                this.name = '';
                this.mobileNumber = this.$store.state.order.orderData.contact_mobile ? this.$store.state.order.orderData.contact_mobile : '';
                this.address = '';
                this.thailandAddressData.postalCode = '';
                this.form_shipping_address_id = -1;
                this.canSplitAddress = true;
            }else{ // แก้ไขที่อยู่เดิม

                this.form_shipping_address_id = id; // เวลากด "แก้ไข" ที่อยู่ เราจะให้เลือกตัวเลือกนี้ไว้ด้วยเลย
                this.name = this.shippingAddresses[id].name;
                if(this.shippingAddresses[id].telephone)
                    this.mobileNumber = this.shippingAddresses[id].telephone;
                else if(this.shippingAddresses[id].mobile)
                    this.mobileNumber = this.shippingAddresses[id].mobile;
                else
                    this.mobileNumber = '';
                this.address = this.shippingAddresses[id].address;

                if(this.isShipOversea){
                    this.internationalAddressData.postalCode = this.shippingAddresses[id] ? this.shippingAddresses[id].zipcode : null;
                    this.is_dummy_receiver_contact = false;

                    if(this.shippingAddresses[id].province)
                        this.internationalAddressData.custom_province = this.shippingAddresses[id].province;
                    if(this.shippingAddresses[id].district)
                        this.internationalAddressData.custom_district = this.shippingAddresses[id].district;
                    if(this.shippingAddresses[id].country)
                        this.internationalAddressData.country = this.shippingAddresses[id].country;
                    if(this.shippingAddresses[id].country_code){
                        this.internationalAddressData.country_code = this.shippingAddresses[id].country_code;
                        this.internationalAddressData.country = this.getCountryNameByCode(this.internationalAddressData.country_code);
                    }
                }else{
                    this.thailandAddressData.postalCode = this.shippingAddresses[id] ? this.shippingAddresses[id].zipcode : null;
                    this.is_dummy_receiver_contact = false;

                    if(this.shippingAddresses[id].subdistrict_id){
                        this.thailandAddressData.selectedPostalList = [
                            this.shippingAddresses[id].subdistrict_id,
                            this.shippingAddresses[id].district_id,
                            this.shippingAddresses[id].province_id
                        ].join(' ');
                    }else{
                        this.thailandAddressData.selectedPostalList = 'custom';
                    }

                    if(this.shippingAddresses[id].province)
                        this.thailandAddressData.custom_province = this.shippingAddresses[id].province;
                    if(this.shippingAddresses[id].district)
                        this.thailandAddressData.custom_district = this.shippingAddresses[id].district;
                    if(this.shippingAddresses[id].subdistrict)
                        this.thailandAddressData.custom_subdistrict = this.shippingAddresses[id].subdistrict;
                }


                if(this.shippingAddresses[id].dummy)
                    this.is_dummy_receiver_contact = true;
            }

            if(this.isExpanded){
                this.scrollTo('#addressStep1', 50);
            }
        },
        // ขนส่งนั้นเป็นขนส่งที่ถูกต้อง force ราคาส่งมาหรือเปล่า (ถ้าลูกค้ามาเปลี่ยน type_id ทีหลัง isForceShippingPrice จะถูกยกเลิก
        isShippingTypeForceShippingPrice(id){
            if(this.isForceShippingPrice
                && id == this.orderData.shipping_type_id){
                return true;
            }
            return false;
        },
        submitUnsetOrderReceiver() {
            store.dispatch('order/setIsShowOverlay', true);
            this.resetValidate();
            const payload = {};
            payload.contact_key = 'receiver';
            if(this.form_shipping_address_is_dealer){
                payload.contact_key = 'dealer_receiver';
            }
            payload.receiver_id = null;
            payload.receiver = null;
            payload.unset_contact = true;

            this.actionUpdateOrderReceiver(payload)
                .then(() => {
                    store.dispatch('order/setIsShowOverlay', false);
                })
                .catch(async (errors) => {
                    await store.dispatch('order/setIsShowOverlay', false);
                    await store.dispatch('order/setIsAjaxResponse', false);
                    await store.dispatch('order/setIsAjaxResponse', true);
                    await store.dispatch('order/setIsAjaxResponseMessage', errors);
                    // console.error(errors[0]);
                })
        },
        submitOrderReceiver({autosave}){
            store.dispatch('order/setIsShowOverlay', true);
            this.resetValidate();
            const payload = {};
            payload.contact_key = 'receiver';
            if(this.form_shipping_address_is_dealer){
                payload.contact_key = 'dealer_receiver';
            }
            if(!this.isAddressEditorFormShow){ // ไม่ได้มีการ 'แก้ไข' หรือ 'เพิ่มที่อยู่ใหม่'  แค่กำลังเลือกใช้ ที่อยู่ ที่มีอยู่แล้ว

                if(this.form_shipping_address_id === -2){
                    this.shippingStep = 2;
                    store.dispatch('order/setIsShowOverlay', false);
                    return;
                }
                payload.receiver_id = this.form_shipping_address_id;
                /* ในกรณีที่ ถ้า backend บังคับให้ส่ง receiver <object> ไปทุกครั้ง เราคงต้องลูปอ่านค่าจาก this.shippingAddresses ส่งไปให้แทน
                let id = this.form_shipping_address_id;
                payload.receiver = {};
                ['address', 'mobile', 'name', 'zipcode',
                    'province', 'district', 'subdistrict',
                    'province_id', 'district_id', 'subdistrict_id'].forEach((key) => {
                        payload.receiver[key] = this.shippingAddresses[id][key];
                });
                */
            }else{

                if(!this.isReceiverInfoInFormReady){
                    store.dispatch('order/setIsShowOverlay', false);
                    return false;
                }
                let receiver = {};
                if(this.isShipOversea){
                    receiver = Object.assign({}, this.$refs.internationalAddress.getPayload);
                }else{
                    receiver = Object.assign({}, this.$refs.thailandAddress.getPayload);
                }
                receiver.address = this.address;
                receiver.telephone = this.mobileNumber;
                if(this.extNumber){
                    if(this.orderData.lang_key == 'th'){
                        receiver.telephone += ' ต่อ ' + this.extNumber;
                    }else{
                        receiver.telephone += ' ext. ' + this.extNumber;
                    }
                }
                receiver.name = this.name;

                if(this.$store.state.order.form.buyerInfoEmail)
                    receiver.email = this.$store.state.order.form.buyerInfoEmail;
                if(this.$store.state.order.form.buyerInfoMobileNumber)
                    receiver.mobile = this.$store.state.order.form.buyerInfoMobileNumber;

                payload.receiver = receiver;

                if(!this.is_dummy_receiver_contact){
                    if(this.editAddressId > 0){
                        payload.receiver_id = this.editAddressId;
                        payload.receiver.contact_id = this.editAddressId;
                    }
                }
            }
            let isSkippedReceiverBefore = this.isSkippedReceiver;
            this.actionUpdateOrderReceiver(payload)
                .then(() => {
                    // auto-save คือ กรณีที่ลูกค้ากดย้อนกลับ หรือหุบกล่อง
                    if(!autosave){
                        this.shippingStep = 2;
                        this.editAddressId = 0; // เพื่อไม่เลือกว่ากำลังแก้ receiver contact ใดๆ
                        if(this.form_shipping_type_id){
                            this.hideOtherProvider();
                        }
                    }
                    if(!this.canEditShippingType || isSkippedReceiverBefore){
                        this.gotoNextStep();
                    }
                    store.dispatch('order/setIsShowOverlay', false);
                })
                .catch(async (errors) => {
                    await store.dispatch('order/setIsShowOverlay', false);
                    await store.dispatch('order/setIsAjaxResponse', false);
                    await store.dispatch('order/setIsAjaxResponse', true);
                    await store.dispatch('order/setIsAjaxResponseMessage', errors);
                    // console.error(errors[0]);
                })
        },
        updateOrderSkipReceiver(skip_flag, autosave) {
            store.dispatch('order/setIsShowOverlay', true);
            this.resetValidate();
            const payload = { skip_flag: skip_flag };
            this.actionUpdateOrderSkipReceiver(payload)
                .then(() => {
                    // auto-save คือ กรณีที่ลูกค้ากดย้อนกลับ หรือหุบกล่อง
                    if(!autosave){
                        if(skip_flag){
                            this.shippingStep = 2;
                            this.editAddressId = 0; // เพื่อไม่เลือกว่ากำลังแก้ receiver contact ใดๆ
                        }else{
                            this.shippingStep = 1;
                            if(this.sortedForAllAddresses.length === 0){
                                this.addNewAddress();
                                this.restoreDataFromSessionStorage();
                            }
                        }
                    }
                    if(!this.canEditShippingType){
                        this.gotoNextStep();
                    }
                    store.dispatch('order/setIsShowOverlay', false);
                })
                .catch(async (errors) => {
                    await store.dispatch('order/setIsShowOverlay', false);
                    await store.dispatch('order/setIsAjaxResponse', false);
                    await store.dispatch('order/setIsAjaxResponse', true);
                    await store.dispatch('order/setIsAjaxResponseMessage', errors);
                })
        },
        skipOrderReceiver({autosave}){
            this.editAddressId = 0;
            this.form_shipping_address_id = 0;
            this.isShowAllAddress = true;

            if(this.isSkippedReceiver){
                this.shippingStep = 2;
            }else{
                this.updateOrderSkipReceiver(1,autosave);
            }
        },
        unSkipOrderReceiver({autosave}){
            this.updateOrderSkipReceiver(0,autosave);
        },
        gotoPreviousStep(){
            store.dispatch('order/setIsShowOverlay', true);

            if(
                this.$store.getters['order/isPassStepBuyerInfo'] // ถ้าผ่านแล้ว แสดงว่าอยากกดกลับไปแก้ไข
                || !this.$store.getters['order/isBuyerInfoHasEnoughData'] // ถ้าข้อมูลยังไม่ครบก็แสดงขึ้นมาให้กรอกใหม่
            ){
                this.$store.commit('order/setForm', {buyerInfoConfirmed: false});
            }

            this.previousStep({currentStep: 'shippingMethod'});
            this.scrollTo('#beginStepInfo');
            setTimeout(function (){
                store.dispatch('order/setIsShowOverlay', false);
            }, 500);
        },
        checkGotoNextStep() {
            // กรณี user ไปกดวิธีการจัดส่งก่อน ให้สามารถกดถัดไปได้ แต่กลับไปที่ step กรอกข้อมูลที่อยู่ อีกครั้ง
            if(!this.isPassReceiverContact && this.isRequiredReceiver){
                this.shippingStep = 1;
                store.dispatch('order/setIsShowOverlay', false);
                this.scrollTo('#addressStep1', 50);
                return false;
            }

            this.nextStep({currentStep: 'shippingMethod', vm: this})
                .then(() => {
                    store.dispatch('order/setIsShowOverlay', false);
                });
        },
        gotoNextStep(){
            store.dispatch('order/setIsShowOverlay', true);
            if(this.form_shipping_type_id !== this.$store.state.order.orderData.shipping_type_id){
                this.saveShippingMethod({shipping_type_id: this.form_shipping_type_id})
                    .then(() => {
                        this.checkGotoNextStep();
                    });
            }else{
                this.checkGotoNextStep();
            }
        },

        // กรณีที่กด gotoPreviousStep ให้จดจำค่าที่กำลังกรอกอยู่ ลงใน sessionStorage ด้วย
        saveCurrentDataInSessionStorage(){
            const session = {};
            for(const key of this.sessionRememberItems) {
                if(key.match(/^thailandAddressData\./))
                    session[key] = this.thailandAddressData[key.replace(/^thailandAddressData\./, '')];
                else
                    session[key] = this[key];
            }
            this.temporary_data = JSON.stringify(session);
            // window.sessionStorage.setItem(this.sessionStorageKey, JSON.stringify(session));
        },
        restoreDataFromSessionStorage(){
            // const json_session = window.sessionStorage.getItem(this.sessionStorageKey);
            const json_session = this.temporary_data;
            if(json_session){
                try {
                    const session = JSON.parse(json_session);
                    for(const key of this.sessionRememberItems) {
                        let value;

                        if(typeof session[key] === 'object')
                            value = Object.assign({}, session[key]);
                        else
                            value = session[key];

                        if(key.match(/^thailandAddressData\./))
                            this.thailandAddressData[key.replace(/^thailandAddressData\./, '')] = value;
                        else
                            this[key] = value;
                    }
                } catch(e) {
                    // JSON parse error
                    this.temporary_data = null;
                }
            }
        },
        validate(){
            if(this.$refs.form_shipping)
                this.$refs.form_shipping.validate();
        },
        resetValidate(){
            if(this.$refs.form_shipping)
                this.$refs.form_shipping.resetValidation();
        },
        async loadMoreAddresses(){
            await this._loadMoreAddresses({type: this.use_this_type});
        },
        async searchAddresses(){
            await this._searchAddresses({type: this.use_this_type, keyword: this.search_textbox});
            this.isSearching = true;
        },
        async abortSearchAddresses(){
            await this._searchAddresses({type: this.use_this_type, keyword: false});
            this.search_textbox = "";
            this.isSearching = false;
        },
        changeShipOversea(item) {
            this.isShipOversea = item.value;
            this.$emit("change", item.value);
            if (this.isShipOversea === item.value) {
                this.$refs.shippingOverseaSelect.blur();
            }

            if(this.form_shipping_address_id > 0){
                this.submitUnsetOrderReceiver();
            }
            if(!this.isAddressEditorFormShow){
                this.form_shipping_address_id = 0;
                this.editAddressId = 0;
                this.isShowAllAddress = true;
            }
            this.resetValidate();
        },
        areaCountryText(area_countries){
            let text = [];
            area_countries.forEach(country => {
                text.push(this.countryDatabase[country]);
            })
            return text.sort().join(', ');
        }
    },
    mounted(){
        this.MY_activated();
    },
    watch: {
        // ปิดฟอร์ม สร้าง/แก้ไข ที่อยู่ ถ้ามีที่อยู่มาอยู่แล้ว  ให้ไปเลือกแทน
        fetchedReceiverAddresses(newValue){
            if(newValue){
                if(this.sortedForAllAddresses.length > 0){
                    if(this.isAddressEditorFormShow){
                        this.closeAddressEditorForm();
                    }
                }
            }
        },
        isPickupType(newValue){
            if(newValue && this.canSkipReceiver){
                this.skipOrderReceiver(true);
            }else if(this.isOrderPickupType){
                this.unSkipOrderReceiver(true);
            }
            this.resetValidate();
        },
        shippingStep(newValue){
            //กันกรณีที่ user คลิกข้ามปุ่ม disable ไปที่ step ถัดไปเลย ให้หุบการแก้ไขที่อยู่
            if(newValue > 1){
                this.editAddressId = 0;
            }else if(newValue == 1){
                if(this.form_shipping_address_id == -1){
                    this.addNewAddress();
                }
            }
        },
        isExpanded(new_val, old_val){
            // console.log(new_val);

            // จะถูกเรียกให้ทำงาน เมื่อ step ตัวมันเองเปลี่ยนจาก collapsed เป็น expand
            if(!old_val && new_val){
                this.MY_activated();
            }
            // ถูกสั่งให้หุบการแสดงผล

            else if(!new_val && old_val){
                this.MY_deactivated();
            }
        },
    },
}
</script>